<template>
  <div>
    <template>
      <div class="cui__uncontent cui__uncontent-my mb-5">
        <div class="container-carousel mb-5">
          <VueSlickCarousel v-bind="bannerSettings" :dots="true">
            <div v-for="(item, index) in dataBanner" :key="index">
              <img v-if="item.dest_url === '/'" :src="item.url" :alt="`banner-${index+1}`"/>
              <a v-else :href="item.dest_url" style="cursor: pointer;">
                <img :src="item.url" :alt="`banner-${index+1}`"/>
              </a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <div class="cui__uncontent">
        <div class="biz-container">
          <template v-if="superiorProducts.length > 0">
            <div class="d-flex justify-content-center align-items-center my-4">
              <div class="ml-2">
                <h3 class="biz-tracking-widest font-weight-bold text-black"> Produk Unggulan </h3>
              </div>
            </div>
            <div class="product-wrapper mb-5">
              <div class="product-grid-list" v-if="showMoreSuperiorProducts">
                <div v-for="(items, index) in superiorProducts.slice(0, 4)" :key="index" class="product-grid-list__item">
                  <CatalogueListItem :product="items" :prices="priceSuperiorProduct" :list_product="superiorProducts"
                    :dataDiscount="discountSuperiorProduct"/>
                </div>
              </div>
              <div class="product-grid-list" v-else>
                <div v-for="(items, index) in superiorProducts" :key="index" class="product-grid-list__item">
                  <CatalogueListItem :product="items" :prices="priceSuperiorProduct" :list_product="superiorProducts"
                    :dataDiscount="discountSuperiorProduct"/>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-5" v-if="showMoreSuperiorProducts">
                <button
                  class="btn-show--more"
                  @click="handleShowMoreSuperiorProducts()"
                >
                  {{ $t('expandButtonCaption') }}
                </button>
              </div>
            </div>
            <a-divider />
          </template>
          <template v-if="newProducts.length > 0">
            <div class="d-flex justify-content-center align-items-center my-4">
              <div class="ml-2">
                <h3 class="biz-tracking-widest font-weight-bold text-black"> {{ $t('purchase.catalogue.new') }} </h3>
              </div>
            </div>
            <div class="product-wrapper">
              <div class="product-grid-list" v-if="showMoreNewProducts">
                <div v-for="(items, index) in newProducts.slice(0, 4)" :key="index" class="product-grid-list__item">
                  <CatalogueListItem :product="items" :prices="priceNewProduct" :list_product="newProducts"
                    :dataDiscount="discountNewProduct"/>
                </div>
              </div>
              <div class="product-grid-list" v-else>
                <div v-for="(items, index) in newProducts" :key="index" class="product-grid-list__item">
                  <CatalogueListItem :product="items" :prices="priceNewProduct" :list_product="newProducts"
                    :dataDiscount="discountNewProduct"/>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-5" v-if="showMoreNewProducts">
                <button
                  class="btn-show--more"
                  @click="handleShowMoreNewProducts()"
                >
                  {{ $t('expandButtonCaption') }}
                </button>
              </div>
            </div>
            <a-divider />
          </template>
          <CatalogueList :isHome="true"/>
        </div>
      </div>
      <div v-if="!loading && mediaKit.length" class="mt-3">
        <MediaKit :mediaKit="mediaKit" />
      </div>
      <div class="vld-parent">
        <loading :active.sync="loading" :can-cancel="false" :is-full-page="fullPage" :height="30" :width="30" />
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */

import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import VueChartist from 'v-chartist'
import Loading from 'vue-loading-overlay'
import InfoBox from '@/components/Widget/InfoBox.vue'
import MediaKit from '@/components/MediaKit'
import VueSlickCarousel from 'vue-slick-carousel'

// import CatalogueList from '@/components/Purchase/Catalogue/CatalogueList.vue'
import CatalogueListItem from '@/components/Purchase/Catalogue/CatalogueListItem.vue'
import CategoryBanner from '@/components/ProductCategory/CategoryBanner.vue'
import CatalogueList from '@/components/Purchase/Catalogue/CatalogueList.vue'

export default {
  components: {
    VueChartist,
    Loading,
    InfoBox,
    MediaKit,
    VueSlickCarousel,
    CatalogueListItem,
    CategoryBanner,
    CatalogueList,
},
  data() {
    return {
      pagination: {},
      fullPage: true,
      position: {
        x: 0,
        y: 0,
      },
      mediaKit: [],
      categories: [],
      categorySelected: null,
      fetching: true,
      showMoreNewProducts: false,
      newProducts: [],
      showMoreSuperiorProducts: false,
      superiorProducts: [],
      products: [],
      perPage: 35,
      total: 0,
      categories: [],
      priceNewProduct: [],
      priceSuperiorProduct: [],
      categorySelected: null,
      warehouse_id: null,
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              nextArrow: <SampleNextArrow />,
              prevArrow: <SamplePrevArrow />,
            },
          },
        ],
      },
      bannerSettings: {
        effect: "coverflow",
        initialSlide: 3,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 2,
        spaceBetween: -200,
        parallax: true,
        parallaxEl: {
          el: ".swiper-parallax-opacity",
          value: "0.2",
        },
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 300,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
        autoplay: true,
        breakpoints: {
          1366: {
            slidesPerView: 1,
            spaceBetween: -300,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
      listCatalogId: [],
      dataDiscount: [],
      discountNewProduct: [],
      discountSuperiorProduct: [],
      dataBanner: [],
      listWarehouseRAW: [],
    }
  },
  mounted() {
    this.fetchProducCategories()
  },
  computed: {
    ...mapState('dashboard', {
      loading: (state) => state.loading,
      commission: (state) => state.commission,
      lastSoldItems: (state) => state.lastSoldItems,
      orderSummary: (state) => state.orderSummary,
      graphSummary: (state) => state.graphSummary,
      optionsCart: (state) => state.optionsCart,
      bestSeller: (state) => state.bestSeller,
    }),
    ...mapState(['user']),
    ...mapState('global', {
      nameClient: (state) => state.nameClient,
      listClientReseller: (state) => state.listClientReseller,
    }),
    ...mapState('user', {
      channelId: (state) => state.channel_id,
    }),

    seller_type() {
      return this.user.seller_type
    },
    loading() {
      return this.$store.state.purchase.loading
    },
    /**
     * @returns {object[]}
     */
    sortItems() {
      return sort
    },
    /**
     * @returns {string}
     */
    selectedSort() {
      return this.$route.query.sort && this.$route.query.order
        ? [this.$route.query.sort, this.$route.query.order].join('__')
        : undefined
    },
  },
  watch: {
    '$route.query'() {
      this.fetchWarehouseId()
      if (this.$route.query.category) {
        const findCategory = this.categories.find(
          (item) => +item.id === +this.$route.query.category,
        )

        this.categorySelected = findCategory.name
      } else {
        this.categorySelected = null
      }
    },
    '$store.state.app.banner_url': {
      deep: true,
      immediate: true,
      handler(value) {
        this.dataBanner = value
      },
    }
  },
  created() {
    // this.getDashboardData()
    // this.getMediaKit()
    //   .then(data => {
    //     this.mediaKit = data
    //   })
  },
  mounted() {
    this.fetchWarehouseId()
  },
  methods: {
    ...mapActions('dashboard', ['getDashboardData', 'getMediaKit']),
    handleShowMoreNewProducts() {
      this.showMoreNewProducts = !this.showMoreNewProducts
    },
    handleShowMoreSuperiorProducts() {
      this.showMoreSuperiorProducts = !this.showMoreSuperiorProducts
    },
    changeDateFormat(value) {
      return moment(value).format('DD MMMM YYYY')
    },
    orderDetail(order_number) {
      this.position.x = window.scrollX
      this.position.y = window.scrollY

      this.$router.push({
        name: 'order.view',
        params: {
          id: order_number,
        },
      })
    },
    getProductContent(data) {
      const listWarehouse = data.map((item) => item.warehouse_id)
      this.fetchDetailWarehouse(listWarehouse)
      // this.fetchProducts(listWarehouse)
    },
    fetchWarehouseId() {
      if (this.$store.state.user.user_data.seller) {
        this.$store
          .dispatch('warehouse/GETWAREHOUSE_SELLER', {
            channel_id: this.$store.state.user.user_data.channel_id,
            seller_id: this.$store.state.user.user_data.seller.map(
              (item) => item.id,
            ),
          })
          .then(({ data }) => {
            this.getProductContent(data)
          })
      } else {
        this.$store
          .dispatch('warehouse/GETWAREHOUSE_COVERAGE', {
            channel_id: this.$store.state.user.user_data.channel_id,
            area_id: this.$store.state.user.user_data.area_id,
          })
          .then(({ data }) => {
            this.getProductContent(data)
          })
      }
    },
    fetchDetailWarehouse(arrWhs) {
      this.$store.dispatch('warehouse/GETWAREHOUSELIST', { id: arrWhs })
        .then(({ data }) => {
          this.listWarehouseRAW = data?.map((item) => {
            return {
              city: item?.address?.city || '--',
              id: item.id,
              title: item.name,
            }
          })
        })
        .catch((error) => {
          this.listWarehouseRAW = []
          console.error(error)
        })
        .finally(() => {
          this.getProductByType(2, arrWhs)
          this.getProductByType(3, arrWhs)
        })
    },
    fetchProducts(warehouse_id) {
      const warehouse_query =
        warehouse_id.length > 1
          ? `warehouse_id=${warehouse_id.join('&warehouse_id=')}`
          : `warehouse_id=${warehouse_id[0]}`
      this.$store
        .dispatch('product/GETPRODUCTLIST', {
          channel_id: this.$store.state.user.user_data.channel_id,
          limit: this.perPage,
          warehouse_query: warehouse_query,
          ...this.$route.query,
        })
        .then(async ({ data, total_row }) => {
          this.listCatalogId = data.map(v => { return v.catalogs[0].id })
          if (this.listCatalogId.length > 0) {
            await this.fetchFlagDiscount()
          }
          const product_id = data.length > 0 && data.map((item) => item.id)
          this.products = data
          this.total = total_row

        })
    },
    async fetchFlagDiscount(listCatalogId = null, type = 0) {
      this.$store
        .dispatch('product/GETDISCOUNT', {
          channel_id: this.$store.state.app.channel_id,
          business_id: this.$store.state.app.business_id,
          catalog_ids: listCatalogId ? listCatalogId.toString() : this.listCatalogId.toString(),
          customer_category_id: this.$store.state.user.user_data.customer_category_id,
        })
        .then((response) => {
          if (type === 2) {
            this.discountNewProduct = response.data
          } else if (type === 3) {
            this.discountSuperiorProduct = response.data
          } else {
            this.dataDiscount = response.data
          }
        })
    },
    async fetchProducCategories() {
      this.$store
        .dispatch('product/GETPRODUCTCATEGORIES', {
          channel_id: this.$store.state.user.channel_id,
        })
        .then((response) => {
          this.categories = response
          if (this.$route.query.category) {
            const findCategory = response.find(
              (item) => +item.id === +this.$route.query.category,
            )

            this.categorySelected = findCategory.name
          } else {
            this.categorySelected = null
          }
        })
    },
    onSortSelectChange(value) {
      const [sort, order] = value.split('__')
      this.$router.push({
        query: {
          ...this.$route.query,
          sort,
          order,
        },
      })
    },
    getImageSrc(slug) {
      switch (slug) {
        case "personal-care":
          return "https://ik.imagekit.io/fepca/Group_2618_WU6T0KDcz.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826893652";

        case "house-hold":
          return "https://ik.imagekit.io/fepca/Group_2619_AInFPjQum.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826893718";

        case "food-and-beverage":
          return "https://ik.imagekit.io/fepca/Group_2620_A_h8JPtPi.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826895004";

        case "household":
          return "https://ik.imagekit.io/fepca/Group_2619_AInFPjQum.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826893718";

        case "food-and-beverages":
          return "https://ik.imagekit.io/fepca/Group_2620_A_h8JPtPi.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826895004";

        case "pharmaceutical":
          return "https://ik.imagekit.io/fepca/Group_2621_mpezEqu-h.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826895539";

        case "pet-food":
          return "https://ik.imagekit.io/fepca/Group_2622_e5kvziEH0.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1654826896281";

        default:
          return;
      }
    },
    async getProductByType(type, warehouse_id) {
      const warehouse_query =
        warehouse_id.length > 1
          ? `warehouse_id=${warehouse_id.join('&warehouse_id=')}`
          : `warehouse_id=${warehouse_id[0]}`
      this.$store
        .dispatch('product/GETPRODUCTBYTYPE', {
          channel_id: this.$store.state.app.channel_id,
          type,
          warehouse_query
        })
        .then(({ data }) => {
          const listCatalogId = []
          data.forEach(item => {
            item.catalogs?.forEach(item2 => {
              if (!listCatalogId.includes(item2.id)) {
                listCatalogId.push(item2.id)
              }
            })
          })
          if (listCatalogId.length > 0) {
            this.fetchFlagDiscount(listCatalogId, type)
          }
          const product_id = data.length > 0 && data.map((item) => item.id)
          const normalizeData = data?.map((item) => {
              const findWhs = this.listWarehouseRAW.find(obj => obj.id === item.warehouse_id)
              return {
                ...item,
                warehouses: [{...findWhs}] || [],
              }
            })

          if (type === 2) {
            this.newProducts = normalizeData
          } else {
            this.superiorProducts = normalizeData
          }

          if (this.superiorProducts.length > 4) this.showMoreSuperiorProducts = true
          else this.showMoreSuperiorProducts = false 

          if (this.newProducts.length > 4) this.showMoreNewProducts = true
          else this.showMoreNewProducts = false 
          let defaultPriceAreaId = this.$store.state.user.user_data.price_area_id
          const isNOO = Boolean(!this.$store.state.user.user_data.seller?.length)
          if (!isNOO) {
            defaultPriceAreaId = this.$store.state.user.user_data.seller.map((obj) => obj.price_area_id).join(',')
          }
          this.$store.dispatch('price/GETPRICEPRODUCT', {
            id: product_id,
            channel_id: this.$store.state.user.user_data.channel_id,
            price_area_id: defaultPriceAreaId,
            customer_category_id: this.$store.state.user.user_data.customer_category_id,
          })
          .then(({ data }) => {
            if (type === 2) {
              this.priceNewProduct = data
            } else {
              this.priceSuperiorProduct = data
            }
          })
        })
    }
  },
}
</script>

<style scoped lang="scss">
.text-black {
  color: var(--biz-base-100);
}

.image {
  border-bottom: 1px solid #eaeaea;
  /* padding: 1rem; */
  position: relative;
}

.card_kategori {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  flex: 1 245px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
}

.card_kategori:last-child {
  margin-right: 0;
}

.card_kategori>img {
  width: 80%;
  margin-bottom: 20px;
  margin: auto;
}

.card_kategori>p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.05em;
  color: #929292;
  text-transform: uppercase;
}


.container-carousel {
  position: relative;

  :deep(.slick-arrow) {
    z-index: 1;
    &.slick-next {
      right: .5rem;
    }
    &.slick-prev {
      left: .5rem;
    }
  }

  :deep(.slick-slide) img {
    object-fit: cover;
    width: 100%;
  }

  :deep(.slick-dots) {
    bottom: .5rem;

    li {
      width: auto;

      button {
        width: auto;

        &:before {
          border-radius: 100%;
          background: #000;
          width: 7.5px;
          height: 7.5px;
          content: '';
        }
      }
    }
  }
}
// .slick-slide {
//   height: 200px;
// }


.product {
  &-filter {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  &-category {
    background: #fff;
    color: #1a1a1a;
    border-radius: 8px;
    padding: 1.5rem;
    width: auto;
    height: auto;

    &__item {
      cursor: pointer;

      img {
        width: 75.82px;
        height: 81.67px;

        @media (max-width: 567px) {
          width: 37px !important;
          height: 45px !important;
        }
      }

      &-title {
        text-transform: uppercase;
        color: #929292;

        @media (max-width: 567px) {
          font-size: 8px !important;
        }
      }
    }
  }
}

.product-list {
  width: 229px;
  // height: 30rem;

  @media (max-width: 567px) {
    width: 45%;
    // height: 25rem;
    margin: 10px 7px !important;
  }
}

.product-section-category {
  padding: 0 98px;

  @media (max-width: 567px) {
    padding: 0;
  }
}

.product-content {
  justify-content: center;

  @media (max-width: 600px) {
    justify-content: space-between;
  }
}

.catalog-icon {
  background: #fff !important;
  padding: 7px 12px;
  border-radius: 50%;
}

.category-selected {
  height: 51px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 3rem 0;

  @media (max-width: 600px) {
    margin: 1rem 0;
  }

  .all {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }
}

.card_kategori {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  flex: 1 245px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    margin: auto;
    cursor: pointer;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
    text-align: center;
    letter-spacing: 0.05em;
    color: #929292;
    text-transform: uppercase;
  }
}

.btn-show--more {
  color: var(--biz-brand-base);
  background: var(--biz-brand-button);
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  text-transform: capitalize;
}
</style>
