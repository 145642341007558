import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import AuthFooterImageLayout from '@/layouts/Auth/AuthFooterImage'
import MainLayout from '@/layouts/Main'
import PassThrough from '@/layouts/PassThrough'
import store from '@/store'

Vue.use(Router)
const webTitle = 'Web'

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '',
      component: MainLayout,
      name: 'dashboard',
      meta: {
        hidden: true,
      },
      children: [
        {
          path: '/dashboard',
          redirect: '/',
        },
        {
          path: '/',
          meta: {
            title: `${webTitle}`,
          },
          component: () => import('./views/dashboard/main'),
        },
        // {
        //   path: '/about/:item',
        //   meta: {
        //     title: `About ${webTitle}`,
        //   },
        //   component: () => import('./views/about'),
        // },
        {
          path: '/page/:pathname',
          meta: {
            title: `${webTitle} - About Sarung Indonesia`,
          },
          component: () => import('@/views/about/DynamicAbout.vue'),
        },
        {
          path: '/about-us',
          name: 'about-us',
          meta: {
            title: `${webTitle} - About Sarung Indonesia`,
          },
          component: () => import('@/views/about/AboutBhs.vue'),
        },
        {
          path: '/privacy-and-policy',
          name: 'privacy-and-policy',
          meta: {
            title: `${webTitle} - Kebijakan dan Privasi`,
          },
          component: () => import('@/views/about/PolicyPrivacy.vue'),
        },
        {
          path: '/customer-service',
          meta: {
            title: `${webTitle} - Layanan Pelanggan`,
          },
          component: () => import('@/views/customer-service/index.vue'),
        },
        {
          path: 'store',
          component: PassThrough,
          name: 'store',
          children: [
            {
              path: '/store/store-settings',
              name: 'store.settings',
              meta: {
                title: `${webTitle} - Store Settings`,
              },
              component: () => import('@/views/store-setting'),
            },
            {
              path: '/store/my-products',
              name: 'my.products',
              meta: {
                title: `${webTitle} - My Products`,
              },
              component: () => import('@/views/store/my-products'),
            },
          ],
        },
        {
          path: '/product/:id',
          name: 'product.detail',
          component: () => import('@/components/Product/Detail'),
        },
        {
          path: '/coming-soon',
          name: 'coming-soon',
          title: `${webTitle} - Coming Soon Feature`,
          component: () => import('./views/coming-soon'),
        },
        {
          path: '/sales/:status?',
          name: 'order.index',
          meta: {
            authRequired: true,
            title: `${webTitle} - Sales Order`,
          },
          component: () => import('./views/Order/Index'),
          children: [
            {
              path: '/sales/view/:id?',
              name: 'order.view',
              meta: {
                title: `${webTitle} - Sales Order`,
              },
              component: () => import('./views/Order/Detail'),
            },
          ],
        },
        {
          path: 'loyalty-redeem',
          component: PassThrough,
          name: 'loyalty-redeem',
          children: [
            {
              path: '/loyalty-redeem/catalogue',
              name: 'Katalog Redeem',
              meta: {
                title: `${webTitle} - Catalog`,
              },
              component: () => import('@/views/loyalty-redeem/catalogue'),
            },
            {
              path: '/loyalty-redeem/catalogue/:id',
              name: 'Katalog Redeem - Detail',
              component: () => import('@/views/loyalty-redeem/catalogue/catalogue-detail.vue'),
            },
            {
              path: '/loyalty-redeem/cart',
              name: 'Cart Redeem',
              meta: {
                title: `${webTitle} - Cart Redeem`,
              },
              component: () => import('@/views/loyalty-redeem/cart'),
            },
            {
              path: '/loyalty-redeem/checkout',
              name: 'Checkout Redeem',
              meta: {
                title: `${webTitle} - Checkout Redeem`,
              },
              component: () => import('@/views/loyalty-redeem/purchase/checkout'),
            },
            {
              path: '/loyalty-redeem/how-to-pay-detail/:order_id',
              name: 'loyalty-redeem.how-to-pay-detail',
              meta: {
                title: `${webTitle} - how-to-pay`,
              },
              component: () => import('@/views/loyalty-redeem/purchase/how-to-pay-detail'),
            },
            {
              path: '/loyalty-redeem/purchase',
              name: 'Katalog Redeem',
              meta: {
                title: `${webTitle} - Purchase`,
              },
              component: () => import('@/views/loyalty-redeem/purchase'),
            },
            {
              path: '/loyalty-redeem/purchase/order/:order_id',
              name: 'Katalog Redeem',
              meta: {
                title: `${webTitle} - Purchase`,
              },
              component: () => import('@/views/loyalty-redeem/purchase/purchase-detail'),
            },
          ],
        },
        {
          path: 'account',
          component: PassThrough,
          name: 'account',
          meta: {
            authRequired: true,
            guest: true,
          },
          children: [
            {
              path: '/account/profile',
              name: 'profile',
              meta: {
                title: `${webTitle} - Profile`,
                guest: true,
              },
              component: () => import('@/views/account/profile'),
            },
            {
              path: '/account/edit-username',
              name: 'edit.username',
              meta: {
                title: `${webTitle} - Edit Username`,
              },
              component: () => import('@/views/account/edit-username'),
            },
            {
              path: '/account/edit-profile',
              name: 'edit.profile',
              meta: {
                title: `${webTitle} - Edit Profil`,
              },
              component: () => import('@/views/account/edit-profile'),
            },
            {
              path: '/account/list-commission',
              name: 'list.commission',
              meta: {
                title: `${webTitle} - List Commission`,
              },
              component: () => import('@/views/account/list-commission'),
            },
            {
              path: '/account/withdraw-request',
              name: 'withdraw.request',
              meta: {
                title: `${webTitle} - Withdraw Request`,
              },
              component: () => import('@/views/account/withdraw-request'),
            },
            {
              path: '/account/ewallet',
              name: 'ewallet.history',
              meta: {
                title: `${webTitle} - Ewallet History`,
              },
              component: () => import('@/views/account/ewallet-history'),
            },
            {
              path: '/account/change-password',
              name: 'change.password',
              meta: {
                title: `${webTitle} - Change Password`,
              },
              component: () => import('@/views/account/change-password'),
            },
            {
              path: '/customer-service',
              meta: {
                title: `${webTitle} - Customer Service Contact`,
              },
              component: () => import('./views/auth/customer-service'),
            },
            {
              path: '/account/bill',
              meta: {
                title: `${webTitle} - Bill`,
              },
              component: () => import('./views/account/bill'),
            },
            {
              path: '/account/shipping-address',
              meta: {
                title: `${webTitle} - Shipping Address`,
              },
              component: () => import('./views/account/shipping-address'),
            },
            {
              path: '/account/shipping-address/add',
              meta: {
                title: `${webTitle} - Shipping Address`,
              },
              component: () => import('./views/account/shipping-address/add-address'),
            },
            {
              path: '/account/shipping-address/edit/:contact_id',
              meta: {
                title: `${webTitle} - Shipping Address`,
              },
              component: () => import('./views/account/shipping-address/edit-address'),
            },
            {
              path: '/account/retur',
              meta: {
                title: `${webTitle} - Retur`,
              },
              component: () => import('./views/account/retur'),
            },
            {
              path: '/account/retur/history',
              meta: {
                title: `${webTitle} - Retur`,
              },
              component: () => import('./views/account/retur/history'),
            },
            {
              path: '/account/retur/create/:warehouse_id',
              meta: {
                title: `${webTitle} - Retur`,
              },
              component: () => import('./views/account/retur/create-retur'),
            },
            {
              path: '/account/retur/detail/:id',
              meta: {
                title: `${webTitle} - Retur`,
              },
              component: () => import('./views/account/retur/detail-retur'),
            },
            {
              path: '/account/bill',
              meta: {
                title: `${webTitle} - Bill`,
              },
              component: () => import('./views/account/bill'),
            },
            {
              path: '/account/bill-history',
              meta: {
                title: `${webTitle} - Bill History`,
              },
              component: () => import('./views/account/bill-history'),
            },
            {
              path: '/account/detail-bill-transaction/:payment_id',
              meta: {
                title: `${webTitle} - Bill History`,
              },
              component: () => import('./views/account/detail-bill-transaction'),
            },
            {
              path: '/account/how-to-pay-bill/:payment_id',
              meta: {
                title: `${webTitle} - Bill How To Pay`,
              },
              component: () => import('./views/account/how-to-pay-bill'),
            },
            {
              path: '/account/bill-payment/:seller_id',
              meta: {
                title: `${webTitle} - Bill Payment`,
              },
              component: () => import('./views/account/bill-payment'),
            },
          ],
        },
        {
          path: 'purchase',
          component: PassThrough,
          name: 'purchase',
          redirect: '/',
          children: [
            {
              path: '/purchase',
              name: 'purchase.index',
              meta: {
                title: `${webTitle} - Purchase List`,
                guest: false,
                redirect: '/order/track',
              },
              component: () => import('./views/purchase/index'),
            },
            {
              path: '/purchase/add',
              name: 'purchase.create',
              meta: {
                title: `${webTitle} - Create Purchase`,
              },
              component: () => import('@/views/purchase/create'),
            },
            {
              path: '/purchase/catalogue',
              name: 'purchase.catalog',
              meta: {
                title: `${webTitle} - Catalog List`,
              },
              component: () => import('@/views/purchase/catalogue'),
            },
            {
              path: '/purchase/catalogue/:category_id',
              name: 'purchase.catalog',
              meta: {
                title: `${webTitle} - Catalog by Category`,
              },
              component: () => import('@/views/purchase/category'),
            },
            {
              path: '/purchase/order/:order_id',
              name: 'purchase.success',
              meta: {
                title: `${webTitle} - Purchase`,
              },
              component: () => import('@/views/purchase/detail'),
            },
            {
              path: '/distributorship/purchase/order/:order_id',
              name: 'distributorship.purchase.success',
              meta: {
                title: `${webTitle} - Purchase`,
              },
              component: () => import('@/views/purchase/detail'),
            },
            {
              path: '/purchase/cart',
              name: 'purchase.cart',
              meta: {
                title: `${webTitle} - Cart`,
              },
              component: () => import('@/views/purchase/cart.vue'),
            },
            {
              path: '/purchase/checkout',
              name: 'purchase.checkout',
              meta: {
                title: `${webTitle} - Checkout`,
              },
              component: () => import('@/views/purchase/checkout'),
            },
            {
              path: '/purchase/payment/:order_id',
              name: 'purchase.payment',
              meta: {
                title: `${webTitle} - Payment`,
              },
              component: () => import('@/views/purchase/payment'),
            },
            {
              path: '/purchase/waiting-payment',
              name: 'purchase.waiting-payment',
              meta: {
                title: `${webTitle} - waiting payment`,
              },
              component: () => import('@/views/purchase/waiting-payment'),
            },
            {
              path: '/purchase/cash-delivery',
              name: 'purchase.cash-delivery',
              meta: {
                title: `${webTitle} - Cash Of Delivery`,
              },
              component: () => import('@/views/purchase/cash-delivery'),
            },
            {
              path: '/purchase/courier',
              name: 'purchase.courier',
              meta: {
                title: `${webTitle} - Melalui Kurir`,
              },
              component: () => import('@/views/purchase/courier'),
            },
            {
              path: '/purchase/how-to-pay/:order_id',
              name: 'purchase.how-to-pay',
              meta: {
                title: `${webTitle} - How to Pay`,
              },
              component: () => import('@/views/purchase/how-to-pay'),
            },
            {
              path: '/purchase/how-to-pay-detail/:order_id',
              name: 'purchase.how-to-pay-detail',
              meta: {
                title: `${webTitle} - How to Pay`,
              },
              component: () => import('@/views/purchase/how-to-pay-detail'),
            },
            {
              path: '/order/track',
              meta: {
                title: `${webTitle} - Order Tracking`,
              },
              component: () => import('@/views/Order/track'),
            },
            {
              path: '/order/track/result',
              meta: {
                title: `${webTitle} - Order Tracking`,
              },
              component: () => import('@/views/Order/track/track-result'),
            },
            {
              path: '/order-confirmation/full-term-of-payment/:order_id',
              name: 'order-confirmation.full-term-of-payment',
              meta: {
                title: `${webTitle} - Order Confirmation`,
              },
              component: () => import('@/views/order-confirmation/full-term-of-payment'),
            },
            {
              path: '/order-confirmation/mix-term-of-payment/:order_id',
              name: 'order-confirmation.mix-term-of-payment',
              meta: {
                title: `${webTitle} - Order Confirmation`,
              },
              component: () => import('@/views/order-confirmation/mix-term-of-payment'),
            },
            {
              path: '/order-confirmation/cash-on-delivery/:order_id',
              name: 'order-confirmation.cash-on-delivery',
              meta: {
                title: `${webTitle} - Order Confirmation`,
              },
              component: () => import('@/views/order-confirmation/cash-on-delivery'),
            },
          ],
        },
      ],
    },

    // label. set here. didnt need layout.
    {
      path: '/order/invoice',
      meta: {
        title: `${webTitle} - Invoice`,
      },
      component: () => import('@/components/InvoiceOpenAuth'),
    },
    {
      path: '/order/invoice/:order_id',
      meta: {
        title: `${webTitle} - Invoice`,
        authRequired: true,
      },
      component: () => import('@/components/Invoice'),
    },
    {
      path: '/account/bill/receipt/:payment_id',
      meta: {
        title: `${webTitle} - Receipt`,
        authRequired: true,
      },
      component: () => import('@/components/Receipt'),
    },
    {
      path: '/account/bill/receipt',
      meta: {
        title: `${webTitle} - Receipt`,
      },
      component: () => import('@/components/ReceiptOpenAuth'),
    },
    {
      path: '/auth/checkemail',
      name: 'checkemail',
      meta: {
        title: `${webTitle} - Check email untuk verifikasi`,
      },
      component: () => import('./views/auth/check-email'),
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: `${webTitle} - Error 404`,
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: `${webTitle} - Error 500`,
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: `${webTitle} - Sign In`,
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: `${webTitle} - Register`,
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/redirect',
          name: 'redirect-login',
          component: () => import('./views/auth/token'),
        },
      ],
    },
    {
      path: '/auth',
      component: AuthFooterImageLayout,
      children: [
        {
          path: '/auth/forgot-password',
          meta: {
            title: `${webTitle} - Forgot Password`,
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/reset-password/:hashId/:token',
          name: 'reset-password',
          meta: {
            title: `${webTitle} - Forgot Password`,
          },
          component: () => import('./views/auth/reset-password'),
        },
        {
          path: '/customer-service-contact',
          meta: {
            title: `${webTitle} - Customer Service Contact`,
          },
          component: () => import('./views/auth/customer-service-contact'),
        },
      ],
    },
    {
      path: '/success-register',
      name: 'success-register',
      component: () => import('./views/auth/success-register'),
    },
    {
      path: '/reg-conf/:linkId',
      name: 'reg-conf',
      component: AuthLayout,
      children: [
        {
          path: '/reg-conf/:linkId',
          name: 'reg-conf',
          meta: {
            title: `${webTitle} - Register Confirmation`,
          },
          component: () => import('./views/auth/register-confirmation'),
        },
      ],
    },

    {
      path: '/link-expired',
      name: 'link-expired',
      hidden: true,
      component: () => import('./views/auth/link-expired'),
    },
    // Redirect to 404
    {
      path: '*',
      redirect: 'auth/404',
      hidden: true,
    },

  ],
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('GETDATAADMIN')

  if (
    !store.state.app.id &&
    to.path !== '/auth/404' &&
    !store.state.app.fetched
  ) {
    return next('/auth/404')
  }

  if (to.path === '/auth/login') {
    if (store.getters['user/authorized'] && !store.getters['user/isGuest'] && !store.getters['user/change_login_req']) {
      return next({
        path: '/',
      })
    }
  }

  if (
    store.getters['user/change_login_req'] &&
    (
      ![
        '/auth/login',
        '/auth/register',
        '/auth/redirect',
        '/auth/forgot-password',
        '/auth/customer-service-contact',
        '/auth/404',
        '/auth/500',
        '/auth/checkemail',
      ]
        .includes(to.path) &&
      ![
        'reset-password',
        'link-expired',
        'reg-conf',
        'privacy-and-policy',
        'about-us',
        'customer-service',
        'success-register',
      ].includes(to.name)
    )
  ) {
    return next('/auth/login')
  }

  if (
    !store.getters['user/authorized'] &&
    (
      ![
        '/auth/login',
        '/auth/register',
        '/auth/redirect',
        '/auth/forgot-password',
        '/auth/customer-service-contact',
        '/auth/404',
        '/auth/500',
        '/auth/checkemail',

      ]
        .includes(to.path) &&
      ![
        'reset-password',
        'link-expired',
        'reg-conf',
        'privacy-and-policy',
        'about-us',
        'customer-service',
        'success-register',
      ].includes(to.name)
    )

  ) {
    store.commit('user/LOGOUT')
    return next('/auth/login')
  }

  if (to.matched.some((record) => record.meta && record.meta.guest === false) && store.getters['user/isGuest']) {
    return next({
      path: to?.meta?.redirect ?? '/auth/login',
      query: !to?.meta?.redirect && { redirect: to.fullPath },
    })
  }

  if (to.matched.some((record) => record.meta && record.meta.authRequired)) {
    const exp = store.getters['user/expireIn']

    if (exp < new Date().getTime() / 1000) {
      store.commit('user/LOGOUT')
    }

    if (!store.getters['user/authorized']) {
      return next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    }
  }
  if (to?.meta?.title) {
    to.meta.title = to.meta.title.replace('Web', store.state.app?.website_title)
  }
  next()
})

export default router
