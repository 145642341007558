<template>
  <div class="product-item" style="cursor: pointer">
    <div class="label-disc" v-if="getDiscountLabel(product)?.length > 0">
      {{ getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value_type === 'fixed' ? `Rp
      ${toCurrency(getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value)} OFF` :
      `${getDiscountLabel(product)?.[0]?.promotions?.[0]?.gift?.[0]?.value}% OFF` }}
    </div>
    <RouterLink :to="{ path: `/product/${product.id}`, query: { warehouse_id: getWarehousesIds } }"
      class="product-item--image">
      <img
        :src="getImageSrc || 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" />
    </RouterLink>

    <div class="product-item--content">
      <RouterLink :to="{ path: `/product/${product.id}`, query: { warehouse_id: getWarehousesIds } }"
        class="description">
        <!-- hide category name on card -->
        <!-- <div class="category">
          {{ product.category_name }}
        </div> -->
        <div class="title">
          {{ product.title }}
        </div>
        <div v-for="(price, index) in getPrices" :key="`price-normal_${index}`" class="price">
          <div class="price-disc-1" v-if="getDiscountLabelById(price.catalog_id)?.length > 0 && index === 0">
            {{ getPrices.at(0) && getPrices[0].selling_price | currency }}{{ getPrices.length > 1 ? ` - ${toCurrency(getPrices[getPrices.length - 1].selling_price)}` : '' }}
          </div>
          <div class="" v-if="getDiscountLabelById(price.catalog_id)?.length > 0 && index === 0">

            <template v-if="getPrices.length > 1">
              {{ getPrices.at(0) && getPrices[0].final_price | currency }}{{ getPrices[0].final_price !== getPrices[getPrices.length - 1].final_price ? ` - ${toCurrency(getPrices[getPrices.length - 1].final_price)}` : '' }}
            </template>
            <template v-else>
              {{ getPrices.at(0) && getPrices[0].final_price | currency }}
            </template>
          </div>
          <div class="price-disc-3" v-if="index === 0 && price.percentage_price_sales !== null">
            +{{ price.percentage_price_sales }}% OFF
          </div>
          <div v-if="(getDiscountLabelById(price.catalog_id)?.length === 0 || price.percentage_price_sales) && index === 0">
            <template v-if="getPrices.length > 1">
              {{ getPrices.at(0) && getPrices[0].final_price | currency }}{{ getPrices[0].final_price !== getPrices[getPrices.length - 1].final_price ? ` - ${toCurrency(getPrices[getPrices.length - 1].final_price)}` : '' }}
            </template>
            <template v-else>
              {{ getPrices.at(0) && getPrices[0].final_price | currency }}
            </template>
          </div>
          <div @click.prevent="toPageTNC" v-if="getDiscountLabelById(price.catalog_id)?.length > 0 && index === 0" class="tnc-text mt-2">Syarat & ketentuan berlaku</div>
        </div>
      </RouterLink>

      <button type="button" class="cart-button" @click.prevent="() => controlModalAddToCart(true)">
        <Bag />
        <span class="biz-hidden md:biz-block mr-1">Tambah</span>
        <span>Keranjang</span>
      </button>
    </div>
    <!-- <div class="product-item--content mt-3">
        <div @click.prevent="toDetail(product.id)" class="title">{{ productTitle }}</div>

        <div class="mt-2" :style="{ height: '70px' }">
          <div class="variant-option my-3">
            <div v-if="option"> {{ option}} </div>
          </div>

          <a-row @click.prevent="toDetail(product.id)" v-for="(price, index) in getPrices" :key="index">
            <a-col :span="24" class="price disc mr-3" v-if="discount && base_price">
              {{ base_price(price.selling_price) && base_price(price.selling_price) | currency }}
            </a-col>
            <a-col :span="24" class="price">
              {{ price.selling_price | currency }} / {{ price.uom }}
            </a-col>
          </a-row>
        </div>

        <div class="mt-3 d-flex justify-content-center align-items-center buttonShop" @click.prevent="() => controlModalAddToCart(true)">
          <div class="d-flex mx-2 justify-content-center align-items-center buttonShop--cart"> + {{ $t('purchase.catalogue.cart') }} </div>
        </div>
      </div> -->
    <!-- <div class="discount-tag" v-if="discount">
      {{ discount }} % <br />
      OFF
    </div> -->

    <ModalAddToCart :visible="visibleModalAddToCart" :product="list_product" :selected_product="product" :price="prices"
      :dataDiscount="dataDiscount" @controlModalAddToCart="controlModalAddToCart" />
  </div>
</template>

<script>
/* eslint-disable */
import ModalAddToCart from '@/components/Purchase/ModalAddToCart.vue'
import Bag from '@/components/Icons/Bag.vue'

export default {
  components: {
    ModalAddToCart,
    Bag,
  },
  props: {
    product: {
      type: Object,
    },
    prices: {
      type: Array,
      default: () => [],
    },
    list_product: {
      type: Array,
      default: () => [],
    },
    dataDiscount: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {
        color: '',
        size: '',
        flavour: '',
      },
      unit: null,
      price: {},
      discountText: '',
      visibleModalAddToCart: false,
      option: null,
      isCart: false,
    }
  },
  methods: {
    toCurrency(value) {
      return `${Intl.NumberFormat('id-ID').format(value || 0)}`
    },
    calculatePrice(calc_price_sales_discount, percentage_price_sales, selling_price, discount, discount_type) {
      // if (discount) {
      // }
      if (percentage_price_sales !== null) {
        if (calc_price_sales_discount?.toString() === 'before') {
          const price_before = selling_price - (selling_price * (percentage_price_sales / 100))
          if (!discount) {
            const price = price_before - (price_before * (discount / 100))
            return price
          } else {
            return price_before
          }
        } else if (calc_price_sales_discount?.toString() === 'after') {
          const price_after = selling_price - (selling_price * (discount / 100))
          if (!discount) {
            const price = price_after - (price_after * (percentage_price_sales / 100))
            return price
          } else {
            return price_after
          }
        } else {
          const price = selling_price - (selling_price * (discount / 100))
          return price
        }
      } else if (percentage_price_sales === null) {
        if (!discount) {
          return selling_price
        } else {
          let discountValue = 0
          if (discount_type?.value_type === 'fixed') {
            discountValue = discount
          } else {
            discountValue = selling_price * (discount / 100)
            if (discountValue > discount_type?.max_value) {
              discountValue = discount_type?.max_value
            }
          }
          let price = selling_price - discountValue
          if (price < 0) {
            price = 0
          }
          return price
        }
      }
    },
    getDiscountLabel(param) {
      const filter = this.dataDiscount.filter(v => { return v.catalog_id === param.catalogs[0].id })
      if (filter.length > 0) {
        if (filter?.[0]?.promotions?.[0]?.gift?.[0]?.value_type) {
          if (filter?.[0]?.promotions?.[0]?.gift?.[0]?.type === 'discount') {
            return filter
          }
        }
        return []
      }
      return []
    },
    getDiscountLabelById(param) {
      const filter = this.dataDiscount.filter(v => { return v.catalog_id === param })
      if (filter.length > 0) {
        if (filter?.[0]?.promotions?.[0]?.gift?.[0]?.value_type) {
          if (filter?.[0]?.promotions?.[0]?.gift?.[0]?.type === 'discount') {
            return filter
          }
        }
        return []
      }
      return []
    },
    handleShowButton(param) {
      this.isCart = param
    },
    controlModalAddToCart(value) {
      this.visibleModalAddToCart = value
    },
    toDetail(product_id, warehouse_id) {
      return this.$router.push({ path: `/product/${product_id}`, query: { warehouse_id: warehouse_id } })
    },
    base_price(price) {
      if (!this.discount) {
        return null
      }

      return price / ((100 - Number(this.discount)) / 100)
    },
    toPageTNC() {
      const routeData = this.$router.resolve({ path: '/page/terms-and-conditions' })
      window.open(routeData.href, '_blank');
    },
  },
  computed: {
    /**
     * @returns { string | null }
     */
    getImageSrc() {
      if (this.product?.showimg_url || this.product.showing_url) {
        return `${this.product?.showimg_url ? this.product.showimg_url : this.product.showing_url}?tr=w-400,h-400,cm-pad_resize,bg-FFFFFF`
      }
      return false
    },
    productTitle() {
      if (!this.product) return null

      // return this.product.title
      const val = this.isMobileView ? 27 : 35
      if (this.product.title.length <= val) {
        return this.product.title
      }
      const string = this.product.title
      const maxLength = val

      let trimmedString = string.substr(0, maxLength)

      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))

      return `${trimmedString} ...`
    },
    productVarian() {
      if (!this.option) return null
      if (this.option.length <= 60) {
        return this.option
      }

      const string = this.option
      const maxLength = 68

      let trimmedString = string.substr(0, maxLength)

      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))

      return `${trimmedString} ....`
    },
    /**
     * @returns { any[] }
     */
    getPrices() {
      if (!this.product || !this.prices) {
        return 0
      }
      let tempPrices = []
      const findPrices = this.prices.find(item => item.product_id === this.product.id)
      if (findPrices?.catalogs?.length > 0) {
        findPrices.catalogs.forEach((obj) => {
          const tempPriceByCatalog = obj.price_list.map((obj2) => {
            let finalPrice = null
            const isHaveDiscount = this.getDiscountLabelById(obj.id)
            finalPrice = this.calculatePrice(
              obj2.calc_price_sales_discount,
              obj2.percentage_price_sales,
              obj2.selling_price,
              isHaveDiscount?.[0]?.promotions?.[0]?.gift?.[0]?.value,
              isHaveDiscount?.[0]?.promotions?.[0]?.gift?.[0]
            )
            return {
              ...obj2,
              catalog_id: obj.id,
              final_price: finalPrice,
              is_have_discount: isHaveDiscount.length > 0,
            }
          })
          tempPrices = tempPrices.concat(tempPriceByCatalog)
        })
        tempPrices.sort((a, b) => {
          return a.final_price - b.final_price
        })
        if (tempPrices?.length > 1) {
          if (tempPrices[0].final_price === tempPrices[tempPrices.length - 1].final_price && tempPrices[0].selling_price === tempPrices[tempPrices.length - 1].selling_price) {
            tempPrices = tempPrices.slice(0, 1)
          }
        }
        return tempPrices
      } else {
        return []
      }
    },
    discount() {
      const prices = this.prices.find(item => item.product_id === this.product.id)

      return prices?.discount ? prices.discount : null
    },
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    getWarehousesIds() {
      const listWarehouse = this.product.warehouses?.map((obj) => obj.id)
      if (listWarehouse?.length > 0) {
        return listWarehouse
      }
      return false
    },
  },
  watch: {
    product: {
      immediate: true,
      handler: function (value) {
        const title = []
        if (value && Array.isArray(value.catalogs) && value.catalogs.length > 1) {
          value.catalogs.forEach((item) => {
            if (item.option) {

              for (const property in item.option) {
                title.push(item.option[property])
              }
            }
          })
        }
        this.option = title.length ? title.join(' - ') : null
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.product-item {
  position: relative;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all ease-in-out .2s;
  border: 1px solid #efefef;

  &--image {
    border-bottom: 1px solid #efefef;

    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
  }

  &--footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    justify-content: flex-end;

    @media (min-width: 576px) {
      max-width: 170px;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    padding: 0 0.75rem;

    @include media-breakpoint-up(md) {
      order: 2;
    }

    .description {
      padding: 1rem 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .title {
      // max-height: 75px !important;
      // color: #000000;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      line-height: 1.5;
      text-transform: capitalize;
      color: #676767;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: .25rem;
      font-size: 12px;

      @include media-breakpoint-up(md) {
        font-size: 14px;
        margin-bottom: .5rem;
      }

    }

    .category {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 1.5;
      color: var(--biz-base-20);

      @include media-breakpoint-up(sm) {
        font-size: 10px;
      }

      @include media-breakpoint-up(md) {
        font-size: 11px;
      }
    }

    .variant-option {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      margin-top: -10px;
      color: #000000;
    }

    .buttonShop {
      cursor: pointer;
      margin-bottom: 14px;

      &--cart {
        color: #ffffff;
        width: 100%;
        height: 40px;
        left: 23px;
        top: 339px;

        border: 2px solid #000000;
        box-sizing: border-box;
        border-radius: 12px;
        background: #000000;
      }

      &--checkout {
        color: #265C9B;
        border: 1px solid #015289;
        background: #015289;
        color: #FFFFFF;
        width: 80px;
        height: 33px;
        border-radius: 5px;
      }
    }
  }

  >div:nth-child(0) {
    width: 100%;
  }

  .price-disc-1 {
    font-style: normal;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 10px;
    line-height: 1.5;
    text-decoration-line: line-through;
    color: #777777;
    margin-right: 2px;

    @media (max-width: 576px) {
      font-size: 9px;
    }
  }

  .price-disc-2 {
    font-style: normal;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    text-transform: capitalize;
    color: #ED1C24;

    @media (max-width: 576px) {
      font-size: 9px;
      margin-right: 2px;
    }
  }

  .price-disc-2-v2 {
    font-style: normal;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    text-decoration-line: line-through;
    text-transform: capitalize;
    color: #ED1C24;

    @media (max-width: 576px) {
      font-size: 9px;
      margin-right: 2px;
    }
  }

  .price-disc-3 {
    font-style: normal;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    text-transform: capitalize;
    color: var(--biz-tertiary-100);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2.66667px 4.66667px;
    gap: 3.33px;
    background: rgba(255, 104, 94, 0.2);
    border-radius: 2.78667px;

    @media (max-width: 576px) {
      font-size: 8px;
      padding: 1.66667px 2.66667px;
    }
  }

  .label-disc {
    display: block;
    padding: .25rem 1rem;
    position: absolute;
    font-weight: 700;
    left: 1rem;
    top: 1rem;
    background: var(--biz-brand);
    border-radius: .15rem;
    color: #fff;
    font-size: 90%;
  }

  .cart-button {
    background: var(--biz-brand-button);
    padding: .5rem 1rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    color: var(--biz-brand-base);
    text-align: center;
    margin-top: auto;
    border: none;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in;
    --translate-y: 0%;
    transform: translateY(var(--translate-y));
    margin-bottom: .75rem;

    :deep(svg) {
      width: 16px;
      margin-top: -1px;
      margin-right: .5rem;
    }

    @include media-breakpoint-up(md) {
      order: 1;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      max-height: 0px;
      --translate-y: -100%;
      margin-bottom: 0;
    }
  }

  .price {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
    // margin-top: 10px;
    font-family: 'Open Sans';
    text-transform: capitalize;
    color: var(--biz-brand-legacy);
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    flex-direction: column;
    // margin-top: auto;

    @media (max-width: 576px) {
      font-size: 12px;
      margin-right: 2px;
    }

    &.slash {
      color: #a7a9c9;
      font-size: .9rem;
      text-decoration-line: line-through;
      margin-bottom: .1rem;
    }

    &.disc {
      color: #a7a9c9;
      font-size: .7rem;
      // margin-bottom: -.5rem;
      margin-bottom: .1rem;
      text-decoration: line-through;
    }
  }

  .discount-tag {
    position: absolute;
    top: -5px;
    right: 7px;
    background: #fa360a;
    color: #fff;
    width: 35px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.25;
    padding: 8px 0;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 3px solid #b3032a;
      position: absolute;
      top: 1px;
      left: 0;
      transform: translate(-100%);
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 14px solid transparent;
      border-top: 8px solid #fa360a;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
    }
  }

  .product-promo {
    position: absolute;
    background: #FF0000;
    color: white;
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 12px;
    top: 12px;
    right: 12px;
  }

  &:hover {
    // box-shadow: 0px 4px 25px -5px rgba(0, 0, 0, .1);
    transform: scale(1.05);

    // .description {
    //   padding-left: .5rem;
    //   padding-right: .5rem;
    // }

    .cart-button {
      @include media-breakpoint-up(md) {
        --translate-y: -100%;
        opacity: 1;
        max-height: 100%;
      }
    }}
}
.tnc-text {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 16px;
  color: var(--biz-brand-legacy);
  width: fit-content;

  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
