<template>
  <div class="cui__uncontent">
    <div class="biz-container">
      <b-modal hide-footer hide-header centered v-model="modalMinimum" body-class="w-[352]">
        <div class="d-flex flex-column align-items-center text-center">
          <div class="mb-1">
            <Warning />
          </div>
          <span>{{ $t('purchase.cart.minimum_price1') }} {{
        Intl.NumberFormat('id-ID', {
          style: "currency", currency:
            "IDR"
        }).format(minimumTransaction)
      }}</span>
          <span>{{ $t('purchase.cart.minimum_price2') }}</span>
        </div>
      </b-modal>

      <CheckoutStep :active="1" class="mb-3"/>

      <div v-if="!isMobileView" class="d-flex justify-content-start align-items-center mb-3">
        <h3> {{ $t('purchase.cart.title') }} </h3>
      </div>

      <div v-if="loading" class="d-flex justify-content-center align-items-center mt-5">
        <a-spin></a-spin>
      </div>

      <div v-else :class="`cart-list ${!isMobileView ? 'gaps2' : 'none-border'}`">
        <div v-if="!carts.length" class="mt-5">
          <h1 class="mx-auto d-flex justify-content-center">
            {{ $t('purchase.cart.not_found_cart') }}
          </h1>
        </div>
        <!-- <div v-if="unProcessedItems.length > 0" class="card">
          <div class="card-body d-flex justify-content-between align-items-center">
            <span>{{ $t('menu.purchase.cart.cannot_be_process') }} ({{ unProcessedItems.length }})</span>
            <button style="background-color: #FEE8E8; border: #FF0000 solid 1px; color: #FF0000;" class="btn">
              {{ $t('menu.purchase.cart.delete_product') }}
            </button>
          </div>
        </div> -->
        <div class="md:biz-flex align-items-start" :style="{ gap: '2rem' }">
          <div :class="`${!isMobileView ? 'w-70' : 'content-mobile'}`">
            <div class="cart-container mb-2">
              <div v-for="(warehouse, idx) in carts" :key="warehouse.id"
                :class="`w-100 border rounded mb-4 ${idx === selectedWarehouseIndex ? 'active-container' : ''}`">
                <div
                  @click="handleChangeCheckbox({ target: { checked: idx !== selectedWarehouseIndex } }, idx)"
                  :class="`d-flex justify-content-between w-100 pt-3 px-3 ${idx === selectedWarehouseIndex ? 'active-header' : ''}`"
                  style="cursor: pointer;">
                  <div class="d-flex align-items-center">
                    <LocationIcon
                      :color="`${idx === selectedWarehouseIndex ? 'var(--biz-brand-base)' : 'var(--biz-brand-button)'}`"
                      style="width: 30px; height: 30px; margin-bottom: 15px;" />
                    <p class="ml-2 w-100" style="">
                      <span style="font-size: 16px;">{{ warehouse.name }}</span><br />
                      <span style="font-size: 13px;">Kota: {{ warehouse?.address?.city || '--' }}</span>
                    </p>
                  </div>
                  <a-checkbox
                    @change="handleChangeCheckbox($event, idx)"
                    :checked="idx === selectedWarehouseIndex"
                    class="mt-1"
                  />
                </div>
                <a-divider class="my-0" />
                <div v-for="(item, index) in warehouse && warehouse.cart_list" :key="index" class="px-3">
                  <div class="cart-list-content__item">
                    <img :src="item?.showimg_url || 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="image-product" class="product-image" />
                    <div class="product-detail">
                      <div class="product-detail__title">
                        <div>{{ item.catalog_title }}</div>
                      </div>
                      <div style="font-size: 12px;">Unit: {{  item.uom }}</div>
                      <span v-if="item.isPreorder" style="color: var(--biz-accent-success-toast)" class="font-weight-bold">{{ $t('purchase.catalogue.preorderStock') }}</span>
                      <span v-else-if="item.stock_is_empty" style="color: #FF0000;" class="font-weight-bold">{{ $t('menu.purchase.cart.stock_empty') }}</span>
                      <span v-else-if="isStockAvailable(item, warehouse.id)" style="color: #FF0000;" class="font-weight-bold1">
                        <a-alert class="mt-2 w-100" style="max-width: fit-content; font-size: 12px;" type="error" show-icon>
                          <template #message>
                            <span style="font-size: 10px;">
                              Stok kurang dari jumlah pesanan, harap ubah jumlah pesanan
                            </span>
                          </template>
                        </a-alert>
                      </span>
                      <br v-if="isStockAvailable(item, warehouse.id) || item.stock_is_empty" />
                      <div class="md:biz-flex">
                        <div v-if="item.promo?.summary?.discount" class="product-detail__price">
                          <div class="size-harga"
                            :style="item?.promo?.promotions ? 'text-decoration-line: line-through' : ''">
                            {{ item.price.currency === 'IDR' ? 'Rp' : 'Rp' }}&nbsp;{{ (item.price.selling_price) | currency_2 }}
                          </div>
                          <div>
                            <div class="discount-tag" v-if="item?.promo?.promotions">
                              <span v-if="item?.promo?.summary.discountType === 'percentage'">
                                {{ item.promo.summary.discount }}% OFF
                              </span>
                              <span v-else>
                                Rp. {{ item.promo.summary.discount | currency }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="final-price ml-md-auto">
                          <span v-if="item.promo?.summary.discount">Rp. {{ Math.round(item.promo.summary.discountPerPrice) | currency_2 }}</span>
                          <span v-else>Rp. {{ item.price.selling_price | currency_2 }}</span>
                        </div>
                        <div class="d-flex" :class="{ 'ml-auto': !item.promo?.summary }">
                          <!-- <div class="md:biz-hidden align-self-center mr-2">
                            {{ $t('purchase.cart.quantity') }}
                          </div> -->
                          <div class="">
                            <div class="qty">
                              <button type="button" style="cursor: pointer"
                                @click.prevent="() => decreamentQty(warehouse.id, item.id)">
                                <i class="fe fe-minus" />
                              </button>
                              <div>
                                <a-input class="centered-input1" :value="item.quantity"
                                  @input="(e) => onChangeQty(warehouse.id, item.id, e)" @keypress="validateQty($event)"
                                  @blur="handleNullQty(warehouse.id, item.id, $event)" />
                              </div>
                              <button style="cursor: pointer"
                                @click.prevent="() => increamentQty(warehouse.id, item.id)">
                                <i class="fe fe-plus" />
                              </button>
                            </div>
                            <div class="required-qty" v-if="item.showDisclaimerQty">{{ $t('purchase.catalogue.qty_req') }} 1
                            </div>
                          </div>
                          <button class="product-detail__remove ml-md-4" style="height: auto"
                            @click.prevent="() => (warehouseIdToDeleted = warehouse.id, itemIdToDeleted = item.id, handleOpenModalDelete(true))">
                            <TrashIcon width="15" height="15" />
                          </button>
                        </div>
                      </div>
                      <div @click.prevent="toPageTNC" v-if="item.promo?.summary?.discount" class="tnc-text">Syarat & ketentuan berlaku</div>
                    </div>
                  </div>
                  <a-divider />
                </div>

              </div>
            </div>
          </div>

          <div v-if="carts?.length > 0"
            :class="!isMobileView ? 'calculation-cart p-3 border rounded w-30' : 'calculation-cart'">
            <!-- <div :class="`${isMobileView ? 'border-b-2' : ''} py-4`">
              <div class="border rounded d-flex py-2 px-4 justify-content-between align-items-center"
                style="cursor: pointer;">
                <div><i class="fe fe-percent font-size-12 mr-2" />Gunakan Kupon</div>
                <i class="fe fe-chevron-right font-size-18" />
              </div>
            </div> -->
            <div v-if="!isMobileView">
              <div class="title-bold">Ringkasan Belanja</div>
              <div class="title-normal mt-2 d-flex justify-content-between align-items-center">
                <div class="text-dark"> Total Harga ( {{ carts[selectedWarehouseIndex]?.cart_list?.length || '--' }} Produk )</div>
                <div class="d-flex justify-content-between total" style="width: 130px">
                  <div><span>&nbsp;</span> Rp.</div>
                  <div> {{ selectedWarehouseIndex !== null ? toCurrency(getSubtotal) : '--' }} <!-- {{withPoints(totalPriceProduct)}} --></div>
                </div>
              </div>
              <a-divider class="my-3" />
              <div class="title-bold d-flex justify-content-between align-items-center">
                <div class="text-dark"> Total Belanja </div>
                <div class="d-flex justify-content-between total" style="width: 130px">
                  <div><span>&nbsp;</span> Rp.</div>
                  <div> {{ selectedWarehouseIndex !== null ? toCurrency(getGrandtotal) : '--' }} <!-- {{withPoints(totalPriceProduct)}} --></div>
                </div>
              </div>
              <button @click.prevent="() => $router.go(-1)"
                class="btn-secondary rounded-lg py-1 mt-4 d-flex align-items-center justify-content-center w-100"><i
                  class="fe fe-arrow-left font-size-16 mr-2" />Kembali Belanja</button>
              <button :disabled="unProcessedItems.length > 0 || !(selectedWarehouseIndex || selectedWarehouseIndex === 0) || disabledButton" @click.prevent="() => checkout(carts[selectedWarehouseIndex])"
                class="btn-primary rounded-lg py-1 mt-3 d-flex align-items-center justify-content-center w-100">Checkout</button>
            </div>
            <div v-if="isMobileView">
              <div class="title-bold mt-3 mb-4">Ringkasan Belanja</div>
              <template v-if="selectedWarehouseIndex !== null">
                <div v-for="({ ...item }, index) in carts[selectedWarehouseIndex].cart_list" :key="index"
                  class="mb-2 biz-grid biz-grid-cols-3 justify-content-between w-100">
                  <div style="grid-column: span 2/span 2;">
                    <div class="text-truncate"> {{ item.catalog_title }} </div>
                    <span class='title-bold'>{{ item.quantity }}pcs</span>
                  </div>
                  <div class="text-right">Rp {{ toCurrency(item.promo.summary.finalPrice) }}</div>
                </div>
              </template>
              <template v-else>
                <div>--</div>
              </template>
              <div class="tot-price-detail d-flex justify-content-between align-items-center mb-5">
                <div :class="`${isMobileView ? 'text-bold' : ''} text-dark`"> {{ $t('purchase.checkout.grand_total') }}
                </div>
                <div class="price">Rp {{ selectedWarehouseIndex !== null ? toCurrency(getGrandtotal) : '--' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="button-actions mobile">
        <button @click.prevent="() => $router.go(-1)" class="btn-back">
          <i class="fe fe-arrow-left font-size-16 mr-2" />
          Kembali Belanja
        </button>
        <button :disabled="unProcessedItems.length > 0 || !(selectedWarehouseIndex || selectedWarehouseIndex === 0) || disabledButton" @click.prevent="() => checkout(carts[selectedWarehouseIndex])"
          :class="!isMobileView ? 'btn-primary rounded-lg py-1 mt-3 d-flex align-items-center justify-content-center' : 'btn-primary rounded-lg px-5'">Checkout</button>
      </div>

      <ModalDelete :visible="visibleModalDelete" @fetchCarts="fetchCarts" :cart_id="cartTemp.id"
        @handleOpenModalDelete="handleOpenModalDelete" cart_i :cart_item_id="itemIdToDeleted"
        :warehouse_id="warehouseIdToDeleted" />
    </div>
  </div>
</template>

<script>
import LocationIcon from '@/components/Icons/Location.vue'
import TrashIcon from '@/components/Icons/Trash.vue'
import ModalDelete from '@/components/Purchase/ModalDelete.vue'
import Warning from '@/components/Icons/Warning.vue'
import { getIdWarehouse, joinWarehouseCart } from '@/utils/purchase'
import debounce from 'lodash/debounce'
import CheckoutStep from './CheckoutStep.vue'
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      carts: [],
      cartTemp: [],
      visibleModalDelete: false,
      itemIdToDeleted: null,
      warehouseIdToDeleted: null,
      loading: false,
      modalMinimum: false,
      quantityToUpdated: null,
      cartIdToUpdated: null,
      valueIncreamentQty: [],
      valueDecreamentQty: [],
      disabledCheckoutBtn: false,
      modalVisible: false,
      minimumTransaction: 0,
      loadingCheckInventory: false,
      unProcessedItems: [],
      dataDiscount: [],
      isLoading: false,
      selectedWarehouseIndex: null,
    }
  },
  components: {
    LocationIcon,
    TrashIcon,
    ModalDelete,
    Warning,
    CheckoutStep,
  },
  computed: {
    ...mapGetters('user', ['isGuest']),
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    getSubtotal() {
      return this.carts[this.selectedWarehouseIndex].cart_list.reduce((total, item) => {
        return total + item.promo?.summary?.finalPrice
      }, 0)
    },
    getTotalDiscount() {
      let totalDiscount = 0
      /* eslint-disable */
      this.carts[this.selectedWarehouseIndex]?.cart_list?.map((obj) => {
        totalDiscount = totalDiscount + (obj?.promo?.summary?.discountValue)
      })
      return totalDiscount
    },
    getGrandtotal() {
      let grandTotal = 0
      /* eslint-disable */
      this.carts[this.selectedWarehouseIndex]?.cart_list?.map((obj) => {
        grandTotal = grandTotal + (obj?.promo?.summary?.finalPrice)
      })
      return grandTotal
    },
    disabledButton() {
      let isDisabled = false
      this.carts[this.selectedWarehouseIndex].cart_list.forEach((item) => {
        const checkStockPerItem = this.isStockAvailable(item, item.warehouse_id)
        if (checkStockPerItem && !item.isPreorder) {
          isDisabled = true
        }
      })
      return isDisabled
    },
  },
  watch: {
    cartTemp: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.$store.commit('purchase/SET_TOTAL_CART', value?.cart_list?.length)
      },
    },
  },
  methods: {
    isStockAvailable(item, whsId) {
      const filteredSameItemId = this.cartTemp.cart_list.filter((obj) => obj.item_id === item.item_id && obj.warehouse_id === whsId)
      const totalParsedQuantity = filteredSameItemId.reduce((total, item) => total + item.parsedQuantity, 0);
      return !Boolean(totalParsedQuantity <= item.qtySellable);
    },
    handleChangeCheckbox(event, index) {
      if (event.target.checked) {
        this.selectedWarehouseIndex = index
      } else {
        this.selectedWarehouseIndex = null
      }
    },
    checkout(warehouse) {
      const total = this.total_price(warehouse && warehouse.id)
      if (total < this.minimumTransaction) {
        this.modalMinimum = true
      } else {
        this.$router.push({ path: '/purchase/checkout', query: { warehouse_id: warehouse.id } })
      }
    },
    async getMinimumTransaction() {
      this.disabledCheckoutBtn = true
      this.$store.dispatch('meta/MINIMUM_TRANSACTION').then(res => {
        this.minimumTransaction = res.meta_value
        this.disabledCheckoutBtn = false
      }).catch(err => {
        console.error(err)
      })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    async fetchCarts() {
      this.loading = true
      this.$store
        .dispatch(this.$store.getters['user/isGuest'] ? 'purchase/GETCARTGUEST' : 'purchase/GETCART', {
          channel_id: this.$store.state.user.user_data.channel_id,
        })
        .then(async ({ data }) => {
          if (data) {
            const newData = Promise.all(data.cart_list.map(async ({ item_id, warehouse_id, uom, ...props }, index) => {
              const checkInventory = await this.getInventory(item_id, warehouse_id, uom)
              if (checkInventory.data.length === 0) {
                this.$store.dispatch('purchase/DELETECART', {
                  channel_id: this.$store.state.user.user_data.channel_id,
                  cart_item_id: props.id,
                  cart_id: data.id,
                })
                return null
              } else {
                let parsedQuantity = 0
                const findUomUnit = checkInventory.data[0].units?.find(({ unit }) => unit === uom)
                if (findUomUnit) {
                  parsedQuantity = findUomUnit.numerator * props.quantity
                }
                return {
                  ...props,
                  item_id,
                  warehouse_id,
                  uom,
                  item_available: props.quantity <= checkInventory.data[0].sellable,
                  stock_is_empty: checkInventory.data[0].sellable === 0,
                  in_stock: {
                    ...checkInventory.data[0],
                  },
                  qtySellable: checkInventory.data[0].sellable,
                  isPreorder: checkInventory.data[0].pre_order,
                  parsedQuantity: parsedQuantity,
                }
              }
            }))
            const newCartList = {
              ...data,
              cart_list: (await newData).filter((obj) => obj != null),
            }
            this.cartTemp = { ...newCartList }
            const warehouse_ids = getIdWarehouse(this.cartTemp.cart_list)
            return this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
              id: warehouse_ids,
            })
          }
        })
        .then(({ data }) => {
          const cart = joinWarehouseCart(data, this.cartTemp.cart_list)
          this.unProcessedItems = cart.filter(({ cart_list }) => cart_list.some(({ stock_is_empty, isPreorder }) => stock_is_empty && !isPreorder))
          this.carts = cart
          if (cart?.length > 0) {
            this.selectedWarehouseIndex = 0
          }
          this.fetchFlagDiscount()
        })
        .catch((err) => {
          console.error({ err })
          this.carts = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    async fetchFlagDiscount() {
      const listCatalogId = []
      this.carts.forEach((cart) => {
        cart.cart_list.forEach((data) => {
          listCatalogId.push(data.item_id)
        })
      })
      this.handleDiscount()
      this.$store
        .dispatch('product/GETDISCOUNT', {
          channel_id: this.$store.state.app.channel_id,
          business_id: this.$store.state.app.business_id,
          catalog_ids: listCatalogId.toString(),
          customer_category_id: this.$store.state.user.user_data.customer_category_id,
        })
        .then((response) => {
          this.dataDiscount = response.data
          this.handleDiscount()
        })
        .catch((err) => {
          console.error({ err })
        })
    },
    handleDiscount() {
      const newCarts = this.carts.map((cart) => {
        const newCartList = []
        cart.cart_list.map((data) => {
          let summary = {
            price: data.quantity * data?.price.selling_price,
            finalPrice: data.quantity * data?.price.selling_price,
            discountType: null,
            discount: 0,
            discountValue: 0,
            discountPerPrice: 0
          }
          const getPromo = this.dataDiscount.find((discount) => discount.catalog_id == data.item_id)
          if (getPromo) {
            getPromo.promotions.forEach((promotion) => {
              if (data?.quantity >= promotion?.min && data?.quantity <= promotion?.max) {
                promotion.gift.forEach((gift) => {
                  if (gift.value_type === 'percentage') {
                    let summaryDiscount = summary.price * (gift.value / 100)
                    if (summaryDiscount > gift.max_value) {
                      summaryDiscount = gift.max_value
                    }
                    let discountValuePerPrice = data?.price.selling_price * (gift.value / 100)
                    if (discountValuePerPrice > gift.max_value) {
                      discountValuePerPrice = gift.max_value
                    }
                    const discountPerPrice = data?.price.selling_price - discountValuePerPrice
                    const finalPrice = discountPerPrice * data?.quantity
                    summary = {
                      ...summary,
                      finalPrice: finalPrice < 0 ? 0 : finalPrice,
                      discountType: 'percentage',
                      discount: gift.value,
                      discountValue: summaryDiscount,
                      discountPerPrice: discountPerPrice < 0 ? 0 : discountPerPrice,
                    }
                  } else {
                    const discountPerPrice = data?.price.selling_price - (gift.value || 0)
                    const finalPrice = discountPerPrice * data?.quantity
                    summary = {
                      ...summary,
                      finalPrice: finalPrice < 0 ? 0 : finalPrice,
                      discountType: 'fixed',
                      discount: gift.value,
                      discountValue: gift.value,
                      discountPerPrice: discountPerPrice < 0 ? 0 : discountPerPrice,
                    }
                  }
                })
              }
            })
          }
          newCartList.push({
            ...data,
            promo: {
              ...getPromo,
              summary,
            },
          })
        })
        return {
          ...cart,
          cart_list: newCartList,
        }
      })

      this.carts = newCarts
    },
    async getInventory(catalog_id, warehouse_id, units) {
      return this.$store.dispatch('warehouse/CHECKINVENTORY', {
        channel_id: this.$store.state.user.user_data.channel_id,
        catalog_id,
        warehouse_id,
        units,
      })
    },
    handleOpenModalDelete(param) {
      this.visibleModalDelete = param
    },
    handleSelectPayment(value) {
      this.modalVisible = true
    },
    total_price(warehouse_id) {
      const { cart_list } = this.carts.find((item) => item.id === warehouse_id)

      const price = cart_list.reduce((acc, current) => {
        return acc + current.quantity * current.price.selling_price
      }, 0)

      return price || 0
    },
    discount_price(warehouse_id, item_id) {
      const findDataByWarehouse = this.carts.find(
        (item) => item.warehouse_id === warehouse_id,
      )
      const findItemData = findDataByWarehouse.cart_list.find(
        (item) => item.id === item_id,
      )

      switch (findItemData.price.discount_type) {
        case 1: {
          return `${findItemData.price.discount_value}% OFF`
        }

        default:
          return null
      }
    },
    increamentQty(warehouse_id, id) {
      this.loadingCheckInventory = true
      this.carts = this.carts.map((item) => {
        if (item.id === warehouse_id) {
          const cart_list = item.cart_list.map((value) => {
            if (
              value.id === id &&
              (value.quantity + 1).toString().length <= 4
            ) {
              value.quantity++
              this.quantityToUpdated = value.quantity
              this.cartIdToUpdated = value.id
              return value
            } else {
              return value
            }
          })

          return {
            ...item,
            cart_list,
          }
        } else {
          return item
        }
      })
      this.debounceQty()
    },
    debounceQty: debounce(function () {
      this.quantityToUpdated && this.updateQty()
    }, 300),
    onChangeQty: debounce(function (warehouse_id, id, params) {
      this.carts = this.carts.map((item) => {
        if (item.id === warehouse_id) {
          const cart_list = item.cart_list.map((value) => {
            if (value.id === id) {
              if (String(params.target.value).length <= 4) {
                if (params.target.value === '' || params.target.value === '0') {
                  if (params.type === 'blur') {
                    value.showDisclaimerQty = false
                    value.quantity = 1
                    this.quantityToUpdated = value.quantity
                    this.cartIdToUpdated = value.id
                  } else {
                    value.showDisclaimerQty = true
                    value.quantity = ''
                    this.quantityToUpdated = value.quantity
                    this.cartIdToUpdated = value.id
                  }
                } else {
                  value.showDisclaimerQty = false
                  value.quantity = parseInt(params.target.value)
                  this.quantityToUpdated = value.quantity
                  this.cartIdToUpdated = value.id
                }
              }
              this.$forceUpdate()
              return value
            } else {
              return value
            }
          })

          return {
            ...item,
            cart_list,
          }
        } else {
          return item
        }
      })

      if (this.quantityToUpdated !== '') {
        this.debounceQty()
        this.disabledCheckoutBtn = false
      } else {
        this.disabledCheckoutBtn = true
      }
    }, 500),
    validateQty(event) {
      const regex = new RegExp(/^[\d]*$/g)
      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
    handleNullQty(warehouse_id, id, event) {
      if (event.target.value === '0' || event.target.value === '') {
        this.onChangeQty(warehouse_id, id, event)
      }
    },
    decreamentQty(warehouse_id, id) {
      this.loadingCheckInventory = true
      this.carts = this.carts.map((item) => {
        if (item.id === warehouse_id) {
          const cart_list = item.cart_list.map((value) => {
            if (value.id === id && value.quantity > 1) {
              value.quantity--
              this.quantityToUpdated = value.quantity
              this.cartIdToUpdated = value.id
              return value
            } else {
              return value
            }
          })

          return {
            ...item,
            cart_list,
          }
        } else {
          return item
        }
      })
      this.debounceQty()
    },
    updateQty() {
      this.$store
        .dispatch('purchase/UPDATECART', {
          cart_item_id: this.cartIdToUpdated,
          new_qty: this.quantityToUpdated,
          channel_id: this.$store.state.user.user_data.channel_id,
          cart_id: this.cartTemp.id,
        })
        .then(() => {
          this.cartIdToUpdated = null
          this.quantityToUpdated = null
          this.fetchCarts()
          this.loadingCheckInventory = false
        })
        .catch((err) => {
          const { data } = err.response
          this.$notification.error({
            message: this.$t('purchase.cart.failed'),
            description:
              data && data.message ? data.message : 'Internal Server Error',
          })
          this.loadingCheckInventory = false
        })
    },
    toPageTNC() {
      const routeData = this.$router.resolve({ path: '/page/terms-and-conditions' })
      window.open(routeData.href, '_blank');
    },
  },
  mounted() {
    this.fetchCarts()
    this.getMinimumTransaction()
  },
}
</script>

<style lang="scss" scoped>
.cart {
  &-icon {
    background: var(--biz-brand-base) !important;
    padding: 0px 4px;
    border-radius: 50%;
  }

  &-list {
    &__row {
      padding: 0.5rem 0 !important;
      background: var(--biz-brand-base) !important;
      padding: 1rem !important;
    }

    &__col {
      text-align: center;
    }

    &-content {
      &__warehouse {
        height: 70px;
        background: var(--biz-brand-base);
        padding: 1rem !important;
      }

      &__item {
        display: flex;
        margin-top: 2rem;
      }

      &__calculation {
        height: 70px;
        padding: 1rem !important;

        .total-price {
          margin-left: 1.9rem;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: var(--biz-base-100);
        }

        .button-checkout {
          margin-right: 1.9rem !important;
          background: var(--biz-base-100);
          border-radius: 5px;
          color: var(--biz-brand-base);
          width: 100px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.product-image {
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  margin-right: 1rem;
}

.product-detail {
  flex-grow: 1;
  width: 100%;

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: .5rem;
    color: var(--biz-base-60);
    font-weight: 600;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 60%;

      &:hover {
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
      }
    }
  }

  &__price {
    display: flex;
    font-size: 12px;
  }

  &__remove {
    background: transparent;
    border: 0;
    margin-left: auto;
  }


  .qty {
    height: 32px;
    max-width: 120px;
    display: flex;
    border: 1px solid var(--biz-primary-20);
    border-radius: .25rem;

    button {
      background-color: transparent;
      border: 0;
      font-size: 90%;
      // border: 1px solid #E3E3E3;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :deep(.ant-input) {
      border: 0;
      height: 32px;
      text-align: center;
      border: 1px solid var(--biz-base-20);
      border-radius: 0;
      margin: -1px -1px;
    }
  }

  .final-price {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--biz-brand-legacy);
    margin-bottom: 1rem;
    margin-top: .25rem;

    @include media-breakpoint-up(md) {
      padding: 2px 1rem;
    }
  }

}

.tot-price-detail {
  font-weight: bold;
  font-size: 110%;
  margin-top: 1rem;

  .price {
    color: var(--biz-brand);
    font-weight: bold;
  }
}

.notif-payment {
  background-color: var(--biz-secondary-100);
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.gaps {
  gap: 5px;
  color: var(--biz-primary-40);
}

.none-border {
  border: none;
}

.border-b-2 {
  border-bottom: 2px solid var(--biz-primary-20) !important;
}

.text-bold {
  font-weight: bold;
}

.flex-gap {
  display: flex;
  gap: 15px;
}

.content-mobile {}

.button-buy-checkout {
  width: 160px;
  background-color: var(--biz-accent-status-3);
  color: var(--biz-brand-base);
  border-radius: 8px;
  margin-right: 5rem;
  margin-left: 20px;
}

.btn-cust {
  background-color: var(--biz-base-100);
  border: none;
  color: var(--biz-brand-base);
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 55px;
}

.btn-cust-dec {
  background-color: var(--biz-brand-base);
  border-width: thin;
  border-color: var(--biz-base-100);
  color: var(--biz-base-100);
  padding: 6px 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 55px;
}

.btn-cust-del {
  background-color: var(--biz-brand-base);
  border: 1.5px solid var(--biz-primary-20) !important;
  color: var(--biz-primary-20);
  padding: 5px 5px;
  width: 23px;
  height: 23px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 55px;
}

/* Darker background on mouse-over */
.btn-cust-del:hover {
  background-color: var(--biz-brand-base);
}

.btn-cust-dec:hover {
  background-color: var(--biz-primary-40);
}

/* Darker background on mouse-over */
.btn-cust:hover {
  background-color: var(--product-status-text-delivered);
}

.button-cust-count {
  padding: 8px 12px;
  background-color: var(--biz-accent-status-3);
  border-radius: 4px;
  color: var(--biz-brand-base);
  font-size: 6px;
  font-weight: bold;
}

.vl {
  border-right: 1px solid var(--biz-accent-status-3);
  height: 27px;
}

.btn-decrement {
  height: 30px !important;
  border-right: 0px !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-increment {
  height: 30px !important;
  border-left: 0px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.required-qty {
  text-align: center;
  font-size: 11px;
  color: var(--biz-secondary-100);
  margin-top: 1px;
  width: 100%;
}

.qty-column {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.size-harga {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: var(--biz-base-20);

  @include media-breakpoint-up(md) {
    font-size: 14px;
  }
}

.calculation-cart {
  top: 180px;
  position: sticky;

  .title-bold {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .title-normal {
    //styleName: Body/Regular/12;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.btn-secondary {
  background-color: var(--biz-brand-base);
  color: var(--biz-base-100);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    background-color: var(--biz-primary-20);
    color: var(--biz-base-100);
  }
}

.btn-primary {
  background-color: var(--biz-base-100);
  color: var(--biz-brand-base);
  border-color: var(--biz-base-100);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    background-color: var(--biz-brand-base) !important;
    color: var(--biz-base-100) !important;
    border-color: var(--biz-base-100) !important;
  }

  &:active {
    background-color: var(--biz-base-100) !important;
    color: var(--biz-brand-base) !important;
    border-color: var(--biz-base-100) !important;
  }

  &:focus {
    background-color: var(--biz-base-100) !important;
    color: var(--biz-brand-base) !important;
    border-color: var(--biz-base-100) !important;
  }

  &:disabled {
    background-color: var(--biz-base-100) !important;
    color: var(--biz-brand-base) !important;
    border-color: var(--biz-base-100) !important;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-actions {
  &.mobile {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: var(--biz-brand-base);
    padding: 1rem 0;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      display: none;
    }

    >* {
      width: 100%;
      flex-grow: 1;
    }

    .btn-primary {
      padding: .45rem 1rem;
      text-align: center;
      font-weight: 500;
    }
  }
}

.btn-back {
  background-color: transparent;
  border: 0;
}

.discount-price {
  color: var(--biz-brand-legacy);
  font-size: 14px;
}

.discount-tag {
  color: var(--biz-brand-base);
  background: var(--biz-brand-legacy);
  padding: 2px 8px;
  border-radius: .15rem;
  margin-left: 10px;
  font-size: 12px;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.cart-container {
  min-width: max-content;

  .active-container {
    border-color: var(--biz-brand-button) !important;
    border-width: 2px !important;
  }

  .active-header {
    background: var(--biz-brand-button);
    color: var(--biz-brand-base)
  }
}
.tnc-text {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 16px;
  color: var(--biz-brand-legacy);
  width: fit-content;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
