<template>
  <div>
    <div id="footer">
      <div :class="$style.footer">
        <div class="biz-container">
          <div class="row">
            <div class="col-12 col-lg-5 mb-4">
              <div class="pr-0 pr-lg-4">
                <div class="w-100">
                  <img class="logo" :src="$store.state.app?.logo" />
                </div>
                <p class="text-dark mt-4" style="white-space: pre-wrap;">{{ getStringContactAddress }}</p>
                <div class="d-flex justify-content-start align-items-center" style="gap: 0px 1rem;">
                  <a v-for="(item) in listSocialMedia" :href="item.url" target="_blank" :key="item.title">
                    <img :src="require(`@/resources/images/${item.title}.svg`)" :alt="item.title" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-7">
              <div class="row">
                <div class="col-12 col-md-4 col-lg-4 footer-section biz-hidden md:biz-block">
                  <h5 class="text-bold title">{{ listFooter1?.title?.toUpperCase() }}</h5>
                  <div class=" col-12 col-lg-4 mt-4 p-0">
                    <a-row style="width : max-content;" justify="start" class="mb-1 d-flex flex-column">
                      <router-link class="mb-3"
                        v-for="(item, index) in listCategoryFooter ?? []"
                        :disabled="item?.children?.length > 0"
                        :key="index" :to="{ path: item.url || `/purchase/catalogue/${item.id}` }">
                        <span class="nama_kategori" style="cursor: pointer">{{
                          item.name
                        }}</span>
                        </router-link>
                    </a-row>
                  </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 footer-section">
                  <h5 class="text-bold title">{{ listFooter2?.title?.toUpperCase() }}</h5>
                  <div class="col-12 col-lg-4 mt-4 p-0">
                    <a-row justify="start" style="width : max-content;" class="mb-1 d-flex flex-column">
                      <router-link class="mb-3"
                        v-for="(item, index) in listFooter2?.items ?? []"
                        :to="{ path: `${item.url}` }" :key="index"><span class="nama_kategori" style="cursor: pointer" v-html="item.title"></span>
                      </router-link>
                    </a-row>
                  </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 footer-section">
                  <h5 class="text-bold title">{{ listFooter3?.title?.toUpperCase() }}</h5>
                  <div class=" col-12 col-lg-4 mt-4 p-0">
                    <a-row style="width : max-content;" justify="start" class="mb-1 d-flex flex-column">
                      <router-link class="mb-3"
                        v-for="(item, index) in listFooter3?.items ?? []"
                        :to="{ path: `${item.url}` }" :key="index"><span class="nama_kategori" style="cursor: pointer" v-html="item.title"></span>
                      </router-link>
                    </a-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="`${$style.footerInner} md:biz-block`">
      <div class="copyright-section">
        <p class="mb-0 order-2 order-md-1 copyright-text">
          {{ copyRight }}
        </p>
        <div class="bank-list order-1 order-md-2">
          <div v-for="(item, index) in listPayment"
            style="background-color: white; min-height: 20px; min-width: 65px; text-align-last: center;" :key="index"
          >
            <img style="object-fit: cover;" :src="item.url" :alt="item.title" :height="16" :width="'90%'" />
          </div>
        </div>

        <div @click.prevent="scrollToTop" class="biz-hidden md:biz-flex align-items-center order-3"
          style="gap: 0px 6px; color: white; cursor: pointer;">
          <div class="scroll-to-top">Scroll to top</div>
          <i class="fe fe-arrow-up font-size-16" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      fetching: true,
      products: [],
      perPage: 35,
      total: 0,
      categories: [],
      prices: [],
      categorySelected: null,
      contactAddress: '',
      listPayment: [],
      listSocialMedia: [],
      listFooter1: [],
      listFooter2: [],
      listFooter3: [],
      copyRight: '',
    }
  },
  computed: {
    ...mapState('user', {
      channelId: (state) => state.channel_id,
      userData: (state) => state.user_data,
    }),
    ...mapState(['meta']),
    loading() {
      return this.$store.state.purchase.loading
    },
    ...mapGetters('user', ['isGuest']),
    getStringContactAddress() {
      return `${this.contactAddress?.first_name} ${this.contactAddress?.last_name}\n${this.contactAddress?.line1}, ${this.contactAddress?.province} ${this.contactAddress?.postal_code}`
    },
    listCategoryFooter() {
      if (this.listFooter1.items?.length > 0) {
        return this.listFooter1.items.map((item) => {
          return {
            ...item,
            name: item.title,
          }
        })
      } else {
        const tempCategoryFooter = this.$store.state.product.categories.filter((obj) => obj.children.length === 0)
        if (tempCategoryFooter) {
          return tempCategoryFooter
        } else {
          return this.$store.state.product.categories.slice(0, 5)
        }
      }
    },
  },
  watch: {
    '$route.query'() {
      this.fetchProducts()
      this.fetchProducCategories()
      if (!this.$route.query.category) {
        this.categorySelected = null
      }
    },
    '$store.state.meta.data': {
      deep: true,
      immediate: true,
      handler(value) {
        /* eslint-disable */
        value?.map((item) => {
          if (item.meta_name === 'social_media') {
            this.listSocialMedia = JSON.parse(String(item.meta_value))
          }
          if (item.meta_name === 'payment_icon') {
            this.listPayment = JSON.parse(String(item.meta_value))
          }
          if (item.meta_name === 'contacts') {
            const tempMetaAddress = JSON.parse(String(item.meta_value))?.address ?? null
            this.contactAddress = tempMetaAddress
          }
          if (item.meta_name === 'footer_block_1') {
            this.listFooter1 = JSON.parse(String(item.meta_value))
          }
          if (item.meta_name === 'footer_block_2') {
            this.listFooter2 = JSON.parse(String(item.meta_value))
          }
          if (item.meta_name === 'footer_block_3') {
            this.listFooter3 = JSON.parse(String(item.meta_value))
          }
          if (item.meta_name === 'copyright') {
            this.copyRight = item.meta_value
          }
        })
      }
    },
  },
  mounted() {
    this.fetchProducCategories()
    // this.fetchProducts()
  },
  methods: {
    async fetchProducts() {
      // this.$store.dispatch('product/GETPRODUCTLIST', {
      //   channel_id: this.$store.state.user.user_data.channel_id,
      //   limit: this.perPage,
      //   ...this.$route.query,
      // })
      //   .then(({ data, total_row }) => {
      //     const product_id = data.map(item => item.id)
      //     this.products = data
      //     this.total = total_row
      //     return this.$store.dispatch('price/GETPRICEPRODUCT', {
      //       id: product_id,
      //       channel_id: this.$store.state.user.user_data.channel_id,
      //       price_area_id: this.$store.state.user.user_data.price_area_id,
      //       customer_category_id: this.$store.state.user.user_data.customer_category_id,
      //     })
      //   })
      //   .then(({ data }) => {
      //     this.prices = data
      //   })
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async fetchProducCategories() {
      this.$store.dispatch('product/GETPRODUCTCATEGORIES', {
        channel_id: this.$store.state.user.channel_id,
      })
        .then((response) => {
          this.categories = response
          if (this.$route.query.category) {
            const findCategory = response.find(item => +item.id === +this.$route.query.category)
            this.categorySelected = findCategory.name
          } else {
            this.categorySelected = null
          }
        })
    },
    onSortSelectChange(value) {
      const [sort, order] = value.split('__')
      this.$router.push({
        query: {
          ...this.$route.query,
          sort,
          order,
        },
      })
    },
    handleSelectCategory(id) {
      if (!id) {
        this.categorySelected = null
        this.$router.push({
          path: '/purchase/catalogue',
          query: { page: 1, limit: this.perPage },
        })
      } else {
        const findCategory = this.categories.find(item => item.id === id)
        this.categorySelected = findCategory.name
        this.$router.push({
          path: '/purchase/catalogue',
          query: { ...this.$router.query, page: 1, category: id },
        })
        // this.$router.go(0)
      }
    },
    getCategoryName(category) {
      const vendor_name = this.$store.state.app.vendor_name
      if (vendor_name.toLowerCase() === 'kino') {
        switch (category.name.toLowerCase()) {
          case 'house hold':
            return 'Household'

          case 'food & beverage':
            return 'food & beverages'

          case 'food beverages':
            return 'food & beverages'

          default:
            return category.name
        }
      }

      return category.name
    },
  },
}
</script>

<style scoped lang="scss">
#footer {
  background: white;
  border-radius: 6px;
  position: relative;
  width: auto;
  border-top: 2px solid #e4e9f0;
}

.logo {
  width: 220px;
}

.logo_apps {
  width: 90%;
  height: 36px;
}

.nama_kategori {
  color: black;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.nama_kategori:hover {
  font-weight: bold;
  text-decoration: underline;
}

.footer-section {
  .title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    height: 43px;
    display: flex;
    align-items: center;
  }
}

.scroll-to-top {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
}


@media (max-width: 600px) {

  .text-bold {
    font-size: 14px;
  }

  .nama_kategori {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 150px;
    margin-bottom: 17px;
    padding: 0;
  }
}

.copyright-section {
  @include biz-container;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: grid;
  gap: 2rem;

  @include media-breakpoint-up(md) {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
  }
}

.bank-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.copyright-text {
  font-size: 12px;
}
</style>
<style lang="scss" module>
@import "./style.module.scss";
</style>
