<template>
  <a-row class="checkout-calculation px-4 py-4 mt-2">
    <a-col class="title mb-3">
      {{ $t('purchase.checkout.order_summary') }}
    </a-col>

    <div class="mt-3 d-flex justify-content-between text-dark summary-title-checkout" v-for="(item, index) in carts ? carts[0].cart_list : []" :key="index">
      <div class="text-truncate" style="width: 55%">
        <div class="text-truncate">{{ item.catalog_title }}</div>
        <div class="text-gray-5">{{ item.quantity }} {{ item.uom }}</div>
      </div>
      <div class="d-flex justify-content-between" style="width: 130px; font-weight: 600">
        <div><span>&nbsp;</span> Rp.</div>
        <div> {{ toCurrency(item?.price.selling_price * item?.quantity) }}</div>
      </div>
    </div>
    <div v-if="dataTransactionType.length > 0" >
      <div v-for="(item, index) in dataTransactionType" :key="index">
        <div v-if="item.type === 'transaction'" class="mt-3 d-flex justify-content-between align-items-center text-dark summary-title-checkout">
          <div> {{ item.name }} </div>
          <div class="d-flex justify-content-between" style="width: 130px; font-weight: 600; color: #FF0000">
            <div><span>-</span> Rp.</div>
            <div> {{ toCurrency(discountMapping(totalPriceProduct, dataDiscount, item.promotion_id, index)) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="voucherUsed && voucherUsed.length > 0" >
      <div v-for="(item, index) in voucherUsed" :key="index">
        <div class="mt-3 d-flex justify-content-between text-dark summary-title-checkout">
          <div> {{ item?.name }} </div>
          <div class="d-flex justify-content-between" style="width: 130px; font-weight: 600; color: #FF0000">
            <div><span>-</span> Rp.</div>
            <div> {{ toCurrency(item?.value) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 d-flex justify-content-between text-dark summary-title-checkout" v-if="tempUsedPoints">
      <div> {{ $t('purchase.checkout.point_used') }} </div>
      <div class="d-flex justify-content-between" style="width: 130px; font-weight: 600; color: #FF0000">
        <div><span>-</span> Rp.</div>
        <div> {{Math.round(tempUsedPoints) ? toCurrency(Math.round(tempUsedPoints)) : 0 }}</div>
      </div>
    </div>
    <div v-if="(tempUsedDepositReturn > 0)">
      <div class="mt-3 d-flex justify-content-between text-dark summary-title-checkout">
        <div> {{ $t('purchase.checkout.return_balance') }} </div>
        <div class="d-flex justify-content-between" style="width: 130px; font-weight: 600; color: #FF0000">
          <div><span>-</span> Rp.</div>
          <div> {{ toCurrency(tempUsedDepositReturn) }}</div>
        </div>
      </div>
    </div>
    <div class="mt-3 d-flex justify-content-between align-items-center text-dark summary-title-checkout" v-if="((selectedShippingMethod?.price || 0) + (isInsurance ? insuranceCost : 0)) > 0">
      <div class="text-truncate" style="width: 55%">
        <div class="text-truncate">Biaya Pengiriman</div>
        <div class="text-gray-5">{{ selectedShippingMethod.name }} - {{ selectedShippingMethod.service.name }}</div>
      </div>
      <div class="d-flex justify-content-between" style="width: 130px; font-weight: 600">
        <div><span>&nbsp;</span> Rp.</div>
        <div> {{ toCurrency(shippingAndInsuranceCost) }}</div>
      </div>
    </div>

    <a-divider />
    <div class="mt-3 d-flex justify-content-between align-items-center summary-title-checkout">
      <div class="text-dark" style="font-size: 16px"><b> {{ $t('purchase.checkout.grand_total') }} </b></div>
      <div class="d-flex justify-content-between total" style="width: 130px; font-weight: 600">
        <div><span>&nbsp;</span> Rp.</div>
        <div>{{ toCurrency(totalPayment) }}</div>
      </div>
    </div>
    <a-divider />
    <div class="description-coin" v-if="getCoinsFromOrder">
      <template v-if="isGuest">
        <div style="display: none">
          Kamu akan mendapatkan <b>{{toCurrency(getPotentialCoin())}}</b> koin jika anda mendaftar jadi reseller
        </div>
      </template>
      <template v-else>
        Kamu akan mendapatkan <b>{{toCurrency(getPotentialCoin())}}</b> Koin dari transaksi ini.<br/>
        <a @click="isModalTermCoin = true">LIHAT KETENTUAN KOIN</a>
      </template>
    </div>

    <a-col
      :span="24"
      v-if="(selectedShippingType === 'shipping' ? Boolean(selectedShippingAddress) : true) && Boolean(selectedBillingAddress) && (selectedShippingType === 'shipping' ? Boolean(selectedShippingMethod) : true)"
      class="confirm-payment mt-4 d-flex align-items-center justify-content-center"
      @click.prevent="handleCheckoutPage"
    >
      <b>Buat Pesanan</b>
    </a-col>
    <a-col
      :span="24"
      v-else
      class="button-disabled mt-4 d-flex align-items-center justify-content-center"
    >
      <b>Buat Pesanan</b>
    </a-col>
    <a-modal
      v-model="isModalTermCoin"
      title="Ketentuan Koin"
      :footer="null"
      :closable="true"
      @cancel="isModalTermCoin = false"
    >
      <div>{{ getLoyaltyState?.statusLoyalty?.name || '-' }}</div>
    </a-modal>
  </a-row>
</template>

<script>
import { getPromotionPerTransaction, getmappingTransaction } from '@/utils/discount/PerTransaction'
import { mapGetters } from 'vuex'

export default {
  props: [
    'carts',
    'subTotal',
    'pointUsed',
    'selectedMixPayment',
    'selectedPayment',
    'creditLimitUsage',
    'dataDiscount',
    'dataTransactionType',
    'selectedShippingAddress',
    'depositReturn',
    'grandTotal',
    'currentTotalCreditLimit',
    'selectedVoucherOnFaktur',
    'voucherOnFaktur',
    'selectedShippingMethod',
    'selectedBillingAddress',
    'coinGift',
    'selectedShippingType',
    'selectedMethod',
    'usedPoints',
    'isInsurance',
    'insuranceCost',
  ],
  components: {
  },
  data() {
    return {
      tempUsedPoints: 0,
      tempUsedDepositReturn: 0,
      tempCreditLimit: 0,
      currentPoints: 0,
      currentCreditLimit: 0,
      currentDepositReturn: 0,
      totalPayment: 0,
      totalPaymentBeforeCreditLimit: 0,
      voucherUsed: [],
      showModalVoucher: false,
      potensialPointIncreased: 0.001,
      // Use Coin Purpose
      seen: false,
      seen_balance: false,
      seen_points: false,
      loyaltyPoints: 0,
      sum: 0,
      x: 'one',
      availablePoint: 0,
      isModalTermCoin: false,
      potensialCoin: 0,
      contentCoinTerm: '',
      getCoinsFromOrder: true,
    }
  },
  computed: {
    ...mapGetters('user', ['isGuest']),
    totalPriceProduct() {
      if (!this.carts.length) {
        return 0
      }
      const warehouse_id = this.carts[0].id
      const { cart_list } = this.carts.find((item) => item.id === warehouse_id)
      const price = cart_list.reduce((acc, current) => {
        return acc + current.quantity * current.price.selling_price
      }, 0)
      this.$emit('handleTotalPriceProduct', price || 0)
      return price || 0
    },
    shippingAndInsuranceCost() {
      return (this.selectedShippingMethod?.price || 0) + (this.isInsurance ? this.insuranceCost : 0)
    },
    getLoyaltyState() {
      return this.$store.state.loyalty
    },
  },
  methods: {
    getPotentialCoin() {
      const shippingCost = this.shippingAndInsuranceCost
      const finalTotal = (this.totalPayment - shippingCost) + this.creditLimitUsage
      // const activeLoyaltyValue = this.getLoyaltyState.statusLoyalty.value
      let stringFunction = this.$store.state.meta?.data?.find((obj) => obj.meta_name === 'loyalty_formula')?.meta_value
      if (stringFunction.charAt(stringFunction.length - 1) === ')') {
        stringFunction = stringFunction.slice(0, -1)
      }
      // if (activeLoyaltyValue) {
      //   return Math.floor(finalTotal * activeLoyaltyValue)
      // }
      if (stringFunction) {
        // eslint-disable-next-line no-new-func
        const functionOrderAmount = new Function('order_amount', 'shipping_cost', `return ${stringFunction}`)
        if (functionOrderAmount) {
          const finalCoin = functionOrderAmount(finalTotal, shippingCost)
          return finalCoin
        } else {
          return -1
        }
      }
      return 0
    },
    onChange(e) {
      this.seen = !this.seen
      if (this.seen) {
        let tempCoin = this.availablePoint
        if (tempCoin > this.totalPayment) {
          tempCoin = this.totalPayment
        }
        this.$store.commit('loyalty/SET_LOYALTY_STORE', {
          usedPoints: Number(tempCoin),
        })
      } else {
        this.$store.commit('loyalty/SET_LOYALTY_STORE', {
          usedPoints: null,
        })
      }
    },
    discountTransaction(total, discount) {
      return getPromotionPerTransaction(total, discount)
    },
    discountMapping(total, discount, id, index) {
      const map = getmappingTransaction(total, discount, id)
      if (map.length > 0) {
        return map[index].discount
      }
    },
    // withPoints(total) {
    //   console.log({ total, pointUsed: this.pointUsed })
    // },
    fixedVoucher(item) {
      if (item?.value_type) {
        return item?.value_type
      } else {
        return null
      }
    },
    applyVoucherOnFaktur(total, listVoucher) {
      let currentTotal = total
      // eslint-disable-next-line
      let tempVoucherUsed = []
      listVoucher.map((obj, index) => {
        if (currentTotal > 0) {
          if (this.fixedVoucher(obj) === 'fixed') {
            currentTotal = currentTotal - obj.value
            tempVoucherUsed.push({
              name: obj.name,
              value: currentTotal < 0 ? obj.value + currentTotal : obj.value,
            })
          } else {
            const totalVoucherValue = (currentTotal * obj.value / 100)
            if (totalVoucherValue > obj?.max_value) {
              currentTotal = currentTotal - obj.max_value
              tempVoucherUsed.push({
                name: obj.name,
                value: obj.max_value,
              })
            } else {
              currentTotal = currentTotal - totalVoucherValue
              tempVoucherUsed.push({
                name: obj.name,
                value: totalVoucherValue,
              })
            }
          }
          if (currentTotal < 0) {
            currentTotal = 0
          }
        }
      })
      this.voucherUsed = tempVoucherUsed
      return currentTotal
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    handleCheckoutPage() {
      if (this.totalPayment === 0) {
        this.$emit('handleSkipPaymentAndCheckout')
      } else {
        this.$emit('handleCheckoutPage', true)
      }
    },
    handleBalanceUsed() {
      let grand_total = this.applyVoucherOnFaktur(this.discountTransaction(this.totalPriceProduct, this.dataDiscount), this.selectedVoucherOnFaktur) + this.shippingAndInsuranceCost
      this.totalPaymentBeforeCreditLimit = grand_total
      this.tempUsedPoints = 0
      this.tempUsedDepositReturn = 0
      this.tempCreditLimit = 0

      if (this.currentPoints > 0) {
        let reduce = 0
        if (this.currentPoints > grand_total) {
          reduce = grand_total
        } else {
          reduce = this.currentPoints
        }
        this.tempUsedPoints = reduce
        grand_total = grand_total - reduce
      }
      if (this.currentCreditLimit > 0) {
        let reduce = 0
        if (this.currentCreditLimit > grand_total) {
          reduce = grand_total
        } else {
          reduce = this.currentCreditLimit
        }
        this.tempCreditLimit = reduce
        grand_total = grand_total - reduce
      }
      if (this.currentDepositReturn > 0) {
        let reduce = 0
        if (this.currentDepositReturn > grand_total) {
          reduce = grand_total
        } else {
          reduce = this.currentDepositReturn
        }
        this.tempUsedDepositReturn = reduce
        grand_total = grand_total - reduce
      }
      this.totalPayment = grand_total
      this.$emit('handleTempCreditLimit', this.tempCreditLimit)
      this.$emit('handleTempUsedPoints', this.tempUsedPoints)
      this.$emit('handleGrandTotal', grand_total)
      this.$emit('handleDepositReturn', this.tempUsedDepositReturn)
      this.$emit('handleGrandTotalBeforeCreditLimit', this.totalPaymentBeforeCreditLimit)
    },
    emitShowModalVoucher(val) {
      this.showModalVoucher = val
    },
    emitSubmitModalVoucher(val) {
      this.$emit('emitSelectedVoucher', val)
      this.voucherUsed = val.filter((obj) => obj.checked === true)

      // this.$emit('emitSelectedMethod', '')
      // this.$emit('emitSelectedMixMethod', '')
      this.showModalVoucher = false

      this.seen = false
      this.$store.commit('loyalty/SET_LOYALTY_STORE', {
        usedPoints: null,
      })
    },
    parserValidateValue(value) {
      // const replaceNonDigits = value.replaceAll(/^\D+/g, '')
      return value
    },
    formatterValidateValue(value) {
      if (value === '0') {
        return ''
      }
      const max = this.totalPaymentBeforeCreditLimit - this.tempCreditLimit
      if (value > max) {
        value = max
      }
      /* eslint-disable */
      // return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      return value
    },
    handleChangeLoyalty(value) {
      const max = this.totalPaymentBeforeCreditLimit - this.tempCreditLimit
      if (value > max) {
        value = max
      }
      this.loyaltyPoints = value
      this.$store.commit('loyalty/SET_LOYALTY_STORE', {
        usedPoints: value,
      })
    },
  },
  watch: {
    sum: {
      deep: true,
      immediate: true,
    },
    x: {
      deep: true,
      immediate: true,
      handler() {
        if (this.x === 'one') {
          let tempCoin = this.availablePoint
          if (tempCoin > this.totalPayment) {
            tempCoin = this.totalPayment
          }
          this.$store.commit('loyalty/SET_LOYALTY_STORE', {
            usedPoints: Number(tempCoin),
          })
        } else {
          this.$store.commit('loyalty/SET_LOYALTY_STORE', {
            usedPoints: null,
          })
          this.loyaltyPoints = 0
        }
      },
    },
    depositReturn(value) {
      this.currentDepositReturn = value
      this.handleBalanceUsed()
    },
    dataTransactionType(value) {
      this.handleBalanceUsed()
    },
    grandTotal(value) {
      this.handleBalanceUsed()
    },
    isInsurance: {
      deep: true,
      immediate: true,
      handler: function () {
        this.handleBalanceUsed()
      },
    },
    selectedShippingMethod: {
      deep: true,
      immediate: true,
      handler: function () {
        this.handleBalanceUsed()
      },
    },
    selectedVoucherOnFaktur: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.handleBalanceUsed()
      },
    },
    pointUsed(value) {
      this.currentPoints = value
      this.handleBalanceUsed()
      // if (value) {
      //   if (value > this.subTotal) {
      //     this.$emit('emitLocalUsedPoints', this.subTotal)
      //     this.tempUsedPoints = this.subTotal
      //   } else {
      //     this.$emit('emitLocalUsedPoints', value)
      //     this.tempUsedPoints = value
      //   }
      // } else {
      //   this.$emit('emitLocalUsedPoints', 0)
      //   this.tempUsedPoints = 0
      // }
    },
    creditLimitUsage(value) {
      this.currentCreditLimit = value
      this.handleBalanceUsed()
    },
  },
  mounted() {
    this.handleBalanceUsed()
    this.$store
      .dispatch('loyalty/GETLOYALTY', {})
      .then(({ data }) => {
        this.availablePoint = data?.available_point
        data.available_point = this.sums
      })
      .catch((err) => console.error(err))
      .finally(() => {
        this.loading = false
      })
    this.$store.state.meta.data.forEach((data) => {
      if (data?.meta_name === 'coin_term') {
        this.contentCoinTerm = data?.meta_value
      }
      if (data?.meta_name === 'loyalty_config') {
        const loyaltyConfig = JSON.parse(data?.meta_value || '{}')
        if ([true, false].includes(loyaltyConfig?.get_coins_from_order)) {
          this.getCoinsFromOrder = loyaltyConfig?.get_coins_from_order
        }
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.checkout-calculation,.btn-voucher {
  background: var(--biz-brand-base);
  border: 0.716px solid var(--biz-primary-20);
  border-radius: 10px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: var(--biz-base-100);

    @media (max-width:600px) {
      font-size: 14px;
    }
  }

  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  .total {
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    color: var(--biz-secondary-100);

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  .term-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: var(--biz-base-100);
  }

  .confirm-payment {
    height: 33px;
    background: var(--biz-base-100);
    border-radius: 6px;
    color: var(--biz-brand-base);
    cursor: pointer;
    font-size: 16px;
    padding: 20px;
  }

  .back-button {
    height: 33px;
    color: var(--biz-base-100);
    border-radius: 10px;
    border: solid 1px var(--biz-base-100);
    background: var(--biz-brand-base);
    cursor: pointer;
  }

  .button-disabled {
    height: 33px;
    border-radius: 10px;
    background: #ababab;
    color: var(--biz-brand-base);
    cursor: not-allowed;
    font-size: 16px;
    padding: 20px;
  }
}
.btn-voucher {
  cursor: pointer;
}
.summary-title-checkout {
  font-size: 14px;
}
.voucher-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .voucher-title-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    font-weight: 600;
    font-size: 16px;
  }
}
.description-coin {
  font-size: 16px;
  color: var(--biz-primary-40);
  b {
    color: var(--biz-accent-2);
  }
  a {
    color: var(--biz-accent-2);
  }
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-dark{
  @media (max-width: 600px) {
    font-size: 12px;
  }
}/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
  display: none;
}
// Force to style CSS (Not recommended) For Select Coin Box
select#list-coin {
  background-color: var(--biz-tertiary-100) !important;
  color: var(--biz-brand-base);
  border-radius: 5px !important;
  height: 40px;
  width: 100%;
  line-height: 20px;
  outline: none; /*Add this*/
  cursor: pointer;
}
.input-coin {
  width: 100%;
}
.term-coin-content {
  font-size: 15px;
}
.title-term-coin {
  color: var(--biz-tertiary-100);
  font-weight: 600;
}
.description-term-coin {
  color: var(--biz-accent-2);
}
.term-coin-value {
  color: var(--biz-primary-40);
}
</style>
